import React, { Component } from 'react';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams } from '../../../utile';
import _ from 'lodash';
import { AddIcon2, ClassetEditIcon, CodeLinkIcon, MailIcon, MoneyIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin } from '../../../store/apiConstants';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { paymentTerms, paymentModes } from '../../../constants';
import { getFormFields, onTextChange, onDropDownChange, onToggleChange, isFormValid } from '../../../utile/formHelper';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
const createFeeTypeFields = require('./createFeeType.json');

class FeeType extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFeeTypeFields, this.props.editData);
        this.state = {
            feeTypes: [],
            formValidations: this.formFields?.formValidations,
            feeTypeFormData: {},
            lazyParams: BasicLazyParams,
            feeTypeDataTable: true,
            openCreateFeeDialog: false,
            editDialog: false,
            feeCategoriesOptions: [],
            admissionCategories: [],
            paymentTerms: [],
            totalRecords: 0
        };

        this.service = new Service();
    }

    getFeeTypes = () => {
        this.setState({
            isLoading: true
        });
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/fee-types?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&academicYear=${localStorage.getItem('userAcademicYear')}`;
        this.service.get(url, true).then((data) => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data?.response) {
                    this.setState({
                        feeTypes: data.res.data.response,
                        totalRecords: data?.res?.data?.totalRecords,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });
    };

    async componentDidMount() {
        await this.getFeeTypes();
        await this.getFeeInstallments();
        await this.getAdmissionCategoryOptions();
        await this.getFeeCategoryOptions();

    }

    handleOpenCreateFeeType = () => {
        this.setState({ openCreateFeeDialog: true, editDialog: false });
    };
    onHideCreateFeeDialog = () => {
        this.setState({
            openCreateFeeDialog: false,
            feeTypeFormData: {}
        });
    };
    onEdit = (rowData) => {
        this.setState({ openCreateFeeDialog: true, editDialog: true, feeTypeFormData: rowData, backupEditData: rowData });
    };
    onSubmitFeeType = () => {
        this.setState({ isLoading: true });
        const AcademicYear = localStorage.getItem('userAcademicYear');
        let data = this.state?.feeTypeFormData;

        const formStatus = isFormValid(createFeeTypeFields, this.formFields.formValidations, data);
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations });
            isCompleteFormValid = false;
        }

        let flag =
            data.feeType !== '' &&
            data.feeType !== undefined &&
            data.feeTypeCode !== undefined &&
            data.feeTypeCode !== '' &&
            data.admissionType !== '' &&
            data.admissionType !== undefined &&
            data.paymentTerms !== '' &&
            data.paymentTerms !== undefined &&
            data.paymentMode !== '' &&
            data.paymentMode !== undefined
                ? true
                : false;
        let payload = this.state.feeTypeFormData;
        payload.academicYear = AcademicYear;
        if (isCompleteFormValid) {
            if (!this.state.editDialog) {
                let url = `${baseUrlAdmin}/fee-types`;
                this.service
                    .post(url, payload, true)
                    .then((data) => {
                        if (data?.status) {
                            this.setState({
                                isLoading: false,
                                editDialog: false,
                                openCreateFeeDialog: false,
                                feeTypeFormData: {}
                            });
                            this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                            this.getFeeTypes();
                        } else {
                            this.setState({ isLoading: false });
                            this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                        }
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Creating the fee type', life: 3000 });
                    });
            } else if (this.state.editDialog) {
                let url = `${baseUrlAdmin}/fee-types`;
                this.service
                    .put(url, payload, true)
                    .then((data) => {
                        if (data?.status) {
                            this.setState({
                                isLoading: false,
                                feeTypeFormData: {},
                                editDialog: false,
                                openCreateFeeDialog: false
                            });
                            this.getFeeTypes();
                            this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        } else {
                            this.setState({ isLoading: false });
                            this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                        }
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating the fee type', life: 3000 });
                    });
            }
        } else {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: 'error', summary: 'Error', detail: 'Please Enter All The Require Fields', life: 3000 });
        }
    };
    getFeeCategoryOptions = () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        let payload = {};
        payload.academicYear = academicYear;
        let url = `${baseUrlAdmin}/fee-category/filtered`;
        this.service.post(url, payload, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each.categoryName,
                    value: each._id
                };
            });

            if (data?.status && data?.res?.data) {
                this.setState({ feeCategoriesOptions: options });
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the fee category options', life: 3000 });
            }
        });
    };
    getAdmissionCategoryOptions = () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`;
        this.service.get(url, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each?.categoryName,
                    value: each?._id
                };
            });

            if (data?.status && data?.res?.data) {
                this.setState({ admissionCategories: data?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the Admission category options', life: 3000 });
            }
        });
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getFeeTypes);
    };

    onEditMultiDD = (selectedValue, key) => {
        if (this.state.editDialog) {
            let isCondition = this.state.backupEditData[key]?.every((item) => selectedValue.includes(item));
            if (!isCondition && this.state.backupEditData.assignedStudents > 0) {
                this.toast?.show({ severity: 'warn', summary: `can't remove already assigned value`, life: 3000 });
                return false;
            }
        }

        return true;
    };

    nothing = () => {
        console.log('fsdfsfsdf');
    };
    getFeeInstallments = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/feeInstallments?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ paymentTerms: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    };
    render() {
        const { feeTypeFormData, formValidations, lazyParams } = this.state;
        const header = <div className="flex justify-content-between bg-white"></div>;

        return (
            <>
                <div className="admin-management">
                    <div className="grid mb-6 mt-2">
                        <div className="col-12 flex justify-content-end align-items-center">
                            <Authorizer permId={PERMISSIONS.CREATE_FEE_TYPE}>
                                <Button onClick={this.handleOpenCreateFeeType} className="addButtonn">
                                    <p className="">Add Fee Type</p>
                                    <AddIcon2 />
                                </Button>
                            </Authorizer>
                        </div>
                    </div>

                    <div className="ma-m30">
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.feeTypes}
                            dataKey="id"
                            paginator
                            lazy
                            // showGridlines
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Fee Types"
                            globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"
                            sortable
                        >
                            <Column
                                header="S.No."
                                className="datatable-cel"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            {/* <p>{(this.state.lazyParams.page) * (this.state.lazyParams.rows) +index.rowIndex +1}</p> */}
                                            {/* <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + rowData.slNo}</p> */}
                                            <p>{index.rowIndex + 1}</p>
                                        </>
                                    );
                                }}
                            />
                            <Column field="feeType" header="Fee Type" className="datatable-cel" sortable />
                            <Column
                                header="Admission Category"
                                className="datatable-cel"
                                sortable
                                body={(rowData, index) => {
                                    // const labels = admissionTypes
                                    //     .filter(type => rowData?.admissionTypes.includes(type.value))
                                    //     .map(type => type.label);

                                    // return (
                                    //     <div>
                                    //         {labels.map((label, index) => (
                                    //             <div key={index}>{label}</div>
                                    //         ))}
                                    //     </div>
                                    // );
                                    return (
                                        <div>
                                            {rowData?.admissionTypes?.map((label, index) => (
                                                <div key={index}>{label}</div>
                                            ))}
                                        </div>
                                    );
                                }}
                            />
                            <Column field="assignedStudents" header="Total Students" className="datatable-cel" sortable />
                            <Column field="assignedFee" header="Total Assigned Fee" className="datatable-cel" sortable />
                            <Column
                                field="paymentType"
                                header="Payment Type"
                                className="datatable-cel"
                                sortable
                                body={(rowData, index) => {
                                    let termLabel = this.state.paymentTerms.find((term) => term._id === rowData?.paymentTerms);
                                    let installmentTerm = this.state.paymentTerms.findIndex((obj) => obj._id === termLabel?._id);
                                    return (
                                        <div>
                                            <div key={index}>{`Term ${installmentTerm + 1}`}</div>
                                        </div>
                                    );
                                }}
                            />
                            <Column
                                className=" "
                                header=""
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <>
                                            <Authorizer permId={PERMISSIONS.UPDATE_FEE_TYPE}>
                                                <div className="flex">
                                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.awl${rowIndex}`} />
                                                    <span
                                                        data-pr-tooltip="Edit Fee Type"
                                                        data-pr-position=""
                                                        className={`ma-ml10 awl${rowIndex}`}
                                                        onClick={() => {
                                                            this.onEdit(rowData);
                                                        }}
                                                    >
                                                        <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                    </span>
                                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.uy${rowIndex}`} />
                                                </div>
                                            </Authorizer>
                                        </>
                                    );
                                }}
                            ></Column>
                        </DataTable>

                        <Toast ref={(el) => (this.toast = el)} position="bottom-right" />

                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                </div>

                <Dialog
                    isOpen={this.state.openCreateFeeDialog}
                    visible={this.state.openCreateFeeDialog}
                    // style={{ width: '30%', height: '75%', position: 'fixed', right: 0, top: '17%', bottom: "10%" }}
                    // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    // className={`dialog-${this.getDeviceSize()}`}
                    style={{ position: 'fixed', right: '0' }}
                    draggable={false}
                    onHide={this.onHideCreateFeeDialog}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2 mb-4">
                                <h3 className="formhead text-center ">{!this.state.editDialog ? 'Add Fee Type' : 'Edit Fee Type'}</h3>
                            </div>
                        );
                    }}
                    // footer={() => (
                    //     <div className='flex justify-content-center mb-4 mt-4'>

                    //         <Button className='submitButton w-7' onClick={this.onSubmitFeeType} >
                    //             {!this.state.editDialog && <AddIcon2 />}
                    //             <p className='submitButtonText'>{!this.state.editDialog ? 'Add ' : 'Save'}</p>
                    //         </Button>
                    //         <Button className='cancelButtonn w-4'
                    //             onClick={this.onHideCreateFeeDialog} >
                    //             <p className='cancelButtonnText'>Cancel</p>
                    //         </Button>
                    //     </div>
                    // )}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div className="">
                            <div className="col-12">
                                <label className="label mb-2 ">
                                    Fee Type Name<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="feeType"
                                        icon={<CodeLinkIcon height={22} />}
                                        className=""
                                        disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Type Name"
                                        value={feeTypeFormData.feeType}
                                        onChange={(e) => onTextChange(e.target.value, 'feeType', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                    />
                                </div>
                                {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>}
                            </div>
                            <div className="col-12">
                                <label className="label">
                                    Fee Type Code<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="feeTypeCode"
                                        icon={<CodeLinkIcon height={22} />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Type Code"
                                        value={feeTypeFormData.feeTypeCode}
                                        disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                        onChange={(e) => {
                                            onTextChange(e.target.value, 'feeTypeCode', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations);
                                        }}
                                        // onChange={(e) => this.setState({ feeTypeCode: e.target.value })}
                                    />
                                </div>
                                {formValidations && !formValidations.fields['feeTypeCode'].isValid && <p className="p-error">{formValidations.fields['feeTypeCode'].errorMsg}</p>}
                            </div>
                            <div className="col-12 ">
                                <label className="label">
                                    Admission Category<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetMultiSelect
                                        icon={<MailIcon />}
                                        id="admissionType"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        // options={admissionTypes}
                                        options={this.state.admissionCategories}
                                        optionLabel="categoryName"
                                        optionValue="_id"
                                        value={feeTypeFormData.admissionType}
                                        onChange={(e) => {
                                            this.onEditMultiDD(e.target.value, 'admissionType')
                                                ? onDropDownChange(e.target.value, 'admissionType', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)
                                                : this.nothing();
                                        }}
                                        placeholder={
                                            <div>
                                                <span className="">Select Admission Type</span>
                                            </div>
                                        }
                                    />
                                </div>
                                {formValidations && !formValidations.fields['admissionType'].isValid && <p className="p-error">{formValidations.fields['admissionType'].errorMsg}</p>}
                            </div>
                            <div className="col-12 ">
                                <label className="label">
                                    Payment Term<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id="paymentTerms"
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.state.paymentTerms.map((term, index) => ({
                                            label: `Term ${index + 1}`,
                                            value: term?._id
                                        }))}
                                        value={feeTypeFormData.paymentTerms}
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'paymentTerms', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations);
                                        }}
                                        placeholder={
                                            <div>
                                                <span className="">Select Payment Term</span>
                                            </div>
                                        }
                                    />
                                </div>
                                {formValidations && !formValidations.fields['paymentTerms'].isValid && <p className="p-error">{formValidations.fields['paymentTerms'].errorMsg}</p>}
                            </div>
                            <div className="col-12 ">
                                <label className="label">
                                    Payment Mode<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetDropdown
                                        icon={<MailIcon />}
                                        id="paymentMode"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={paymentModes}
                                        value={feeTypeFormData.paymentMode}
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'paymentMode', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations);
                                        }}
                                        placeholder={
                                            <div>
                                                <span className="">Select Payment Mode</span>
                                            </div>
                                        }
                                    />
                                </div>
                                {formValidations && !formValidations.fields['paymentMode'].isValid && <p className="p-error">{formValidations.fields['paymentMode'].errorMsg}</p>}
                            </div>
                            <div className="col-12 ">
                                <Checkbox
                                    // inputId="isRefundableFee"
                                    checked={feeTypeFormData.isRefundableFee}
                                    id="isRefundableFee"
                                    value={feeTypeFormData.isRefundableFee}
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    // style={{borderRadius: '20px',borderWidth:'4px'}}
                                    // className="p-checkbox"
                                    onChange={(e) => onToggleChange(e.checked, 'isRefundableFee', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isRefundableFee" className="label ml-2">
                                    Refundable Fee
                                </label>
                            </div>
                            <div className="col-12  ">
                                <Checkbox
                                    checked={feeTypeFormData.isProRata}
                                    value={feeTypeFormData.isProRata}
                                    id="isProRata"
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    onChange={(e) => onTextChange(e.checked, 'isProRata', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isProRata" className="label ml-2">
                                    Enable Pro-rata
                                </label>
                            </div>
                            <div className="col-12 ">
                                <Checkbox
                                    checked={feeTypeFormData.isTransportFeeType}
                                    value={feeTypeFormData.isTransportFeeType}
                                    id="isTransportFeeType"
                                    disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    onChange={(e) => onToggleChange(e.checked, 'isTransportFeeType', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isTransportFeeType" className="label ml-2">
                                    Is Transport Fee Type
                                </label>
                            </div>
                            {feeTypeFormData.isTransportFeeType && (
                                <div className="col-12 ">
                                    <Checkbox
                                        checked={feeTypeFormData.isDifferentialFee}
                                        value={feeTypeFormData.isDifferentialFee}
                                        disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                        id="isDifferentialFee"
                                        // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                        onChange={(e) => onToggleChange(e.checked, 'isDifferentialFee', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                    />
                                    <label htmlFor="isDifferentialFee" className="label ml-2">
                                        Is Differential Fee{' '}
                                    </label>
                                </div>
                            )}
                            <div className="col-12   flex">
                                <Checkbox
                                    value={feeTypeFormData.isgst}
                                    checked={feeTypeFormData.isgst}
                                    className="label mt-2"
                                    id="isgst"
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    onChange={(e) => onToggleChange(e.checked, 'isgst', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isgst" className="label ml-2 mt-2">
                                    GST/VAT
                                </label>
                                <div className="ml-4">
                                    <ClassetInputText
                                        id="gst"
                                        className=" "
                                        height="48px"
                                        width="250px"
                                        placeholder="GST/VAT %"
                                        value={feeTypeFormData.gst}
                                        disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                        // disabled={feeTypeFormData.isgst}
                                        onChange={(e) => onTextChange(e.target.value, 'gst', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                    />
                                </div>
                            </div>

                            {!feeTypeFormData.isTransportFeeType && (
                                <div className="col-12 ">
                                    <label className="label">Fee Categories</label>
                                    <div className="mt-2">
                                        <ClassetMultiSelect
                                            icon={<MoneyIcon />}
                                            id="feeCategories"
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={this.state.feeCategoriesOptions}
                                            value={feeTypeFormData.feeCategories}
                                            onChange={(e) => {
                                                this.onEditMultiDD(e.target.value, 'feeCategories')
                                                    ? onDropDownChange(e.target.value, 'feeCategories', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)
                                                    : this.nothing();
                                            }}
                                            placeholder={
                                                <div>
                                                    <span className="">Select Fee Category</span>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-content-center mb-4 mt-4">
                                <Button className="submitButton w-7 mr-2" onClick={this.onSubmitFeeType}>
                                    {!this.state.editDialog && <AddIcon2 />}
                                    <p className="submitButtonText">{!this.state.editDialog ? 'Add ' : 'Save'}</p>
                                </Button>
                                <Button className="cancelButtonn w-4" onClick={this.onHideCreateFeeDialog}>
                                    <p className="cancelButtonnText">Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
export default FeeType;
