import React, { Component } from 'react';
import ClassetDropdown from '../../../../classetComponents/classetDropDown';
import { FileAttachmentIcon, MailIcon } from '../../../svgIcons';
import ClassetInputText from '../../../../classetComponents/classetInputText';
import ClassetTextArea from '../../../../classetComponents/classetTextArea';
import { Button } from 'primereact/button';
import FileUpload from '../../../fileUpload';
import ClassetCalendar from '../../../../classetComponents/classetCalender';
import { getFormFields, isFormValid, isValidEmail, isValidMobile, onDropDownChange, onTextChange } from '../../../../utile/formHelper';
import { cloneDeep, create } from 'lodash';
import { cities, geCountries, geQRCodeTypes, getESTDYears, states, States, StatesAndDirstricts, trimObj } from '../../../../utile';
import { InputText } from 'primereact/inputtext';
import Service from '../../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import withRouter from '../../../lib/withRouter';
import { InputSwitch } from 'primereact/inputswitch';
const createOrganizationFields = require('./createOrganization.json');
const formFields = createOrganizationFields;

class Organization extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createOrganizationFields, this.props.editOrg || {});

        this.state = {
            org: this.props.org,
            formValidations: this.props.formValidations,
            isSubmitClick: false,
            verify: this.props.verify
        };
        this.service = new Service();
    }
    componentDidMount() {
    }

    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => ({
            org: {
                ...prevState.org,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };
    _onProgress = (filesInfo) => { };

    _afterFilesUploaded = (files) => { };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _onLogoSquareSuccess = (fileName, data) => {
        this.addAttachment('logoSquare', fileName, data);
    };
    _onLogoRectangleSuccess = (fileName, data) => {
        this.addAttachment('logoRectangle', fileName, data);
    };
    _onAgreementSuccess = (fileName, data) => {
        this.addAttachment('agreement', fileName, data);
    };
    _onBannerImageSuccess = (fileName, data) => {
        this.addAttachment('idCardBanner', fileName, data);
    };
    _onCertificateSuccess = (fileName, data) => {
        this.addAttachment('gstCertificate', fileName, data);
    };
    _onSignatureImageSuccess = (fileName, data) => {
        this.addAttachment('idCardSignature', fileName, data);
    };
    _onWaterMarkSuccess = (fileName, data) => {
        this.addAttachment('idCardWatermark', fileName, data);
    };
    sendOTPClick = (field, value, type) => {
        let { org } = this.state;

        if (!type && !isValidMobile(value)) {
            this.toast.show({
                severity: 'error',
                summary: 'Invalid Mobile number',
                detail: 'Please enter correct phone number ',
                life: 3000
            });
            return;
        }
        if (type == 'email' && !isValidEmail(value)) {
            this.toast.show({
                severity: 'error',
                summary: 'Invalid Email Id',
                detail: 'Please enter the valid email Id',
                life: 3000
            });
            return;
        }

        if (this.state.verify[field + 'OtpSentCount'] > 2) {
            this.toast.show({
                severity: 'error',
                summary: 'You reached OTP send limit.',
                detail: "you can't send more than 3 OTPs.",
                life: 3000
            });
        } else {
            this.setState({
                isLoading: true,
            });
            this.state.verify[field] = true;
            let url = ``;
            if (type == 'email') {
                url = `${baseUrlAdmin}/otp/mail/send/${org[field]}`;
            } else {
                url = `${baseUrlAdmin}/otp/send/+91${org[field]}`;
            }

            this.service
                .post(url, {}, true)
                .then((res) => {
                    if (res && res.status && res.res.success) {
                        this.setState((prevState) => {
                            return {
                                isLoading: false,
                                verify: {
                                    ...prevState.verify,
                                    [field]: false,
                                    [field + 'OtpSent']: true,
                                    [field + 'OtpSentCount']: (prevState.verify[field + 'OtpSentCount'] += 1)
                                }
                            };
                        });
                        this.toast.show({
                            severity: 'success',
                            summary: 'OTP Sent successfully',
                            detail: 'OTP sent. Please verify',
                            life: 3000
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.state.verify[field] = false;
                        this.toast.show({
                            severity: 'error',
                            summary: 'Some error occured',
                            detail: res.errMessage,
                            life: 3000
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    this.state.verify[field] = false;
                    console.log(e);
                });
        }
    };
    validateOTP = (field, type) => {
        let { org, verify } = this.state;
        const verifyField = org[field];
        let otp = verify[field + 'Otp'];
        if (otp.length == 4) {
            this.setState({
                isLoading: true
            });

            let url = '';

            if (type == 'email') {
                url = `${baseUrlAdmin}/otp/mail/verifyemail/${verifyField}/${otp}`;
            } else {
                url = `${baseUrlAdmin}/otp/verifyphone/+91${verifyField}/${otp}`;
            }

            this.service
                .post(url, {}, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState((prevState) => {
                            return {
                                isLoading: false,
                                verify: {
                                    ...prevState.verify,
                                    [field]: true
                                }
                            };
                        });
                        this.toast.show({
                            severity: 'success',
                            summary: 'OTP Verified',
                            detail: 'OTP successfully verified.',
                            life: 3000
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({
                            severity: 'error',
                            summary: 'Some error occured',
                            detail: res.errMessage ? res.errorMsg : res.res && res.res.message,
                            life: 3000
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    console.log(e);
                });
        } else {
            this.toast.show({
                severity: 'error',
                summary: 'Invalid OTP',
                detail: 'OTP must be 4 characters and numbers',
                life: 3000
            });
        }
    };
    onChangeOTP = (field, value) => {
        if (value.length > 4 || isNaN(value)) {
            this.toast.show({
                severity: 'error',
                summary: 'Invalid OTP',
                detail: 'OTP must be 4 characters and numbers',
                life: 3000
            });
        } else {
            this.setState((prevState) => {
                return {
                    isLoading: false,
                    verify: {
                        ...prevState.verify,
                        [field + 'Otp']: value
                    }
                };
            });
        }
    };
    createOrganization = async () => {
        const {
            emailOperationalOtp,
            emailOperational,
            emailOperationalOtpSent,
            emailOperationalOtpSentCount,
            emailAccountsOtp,
            emailAccounts,
            emailAccountsOtpSent,
            emailAccountsOtpSentCount,
            mobileOperationalOtp,
            mobileOperational,
            mobileOperationalOtpSent,
            mobileOperationalOtpSentCount,
            mobileAccountsOtp,
            mobileAccounts,
            mobileAccountsOtpSent,
            mobileAccountsOtpSentCount
        } = this.state.verify;
        this.setState({ isSubmitClick: true });
        const { org, verify } = this.state;
        org.tenureEndDate = org?.tenureEndDate || new Date()
        org.tenureStartDate = org?.tenureStartDate || new Date()
        const formStatus = isFormValid(createOrganizationFields, this.state.formValidations, trimObj(org));
        this.setState({ isLoading: true });
        if (!formStatus.isFormValid) {
            return this.toast.show({
                severity: 'error',
                summary: 'Please Fill All Required Fields',
                life: 3000
            });
        }
        if (!this.props.editOrg) {
            if (!emailAccounts) return this.toast.show({ severity: 'error', summary: 'Please Verify email Accounts Otp', life: 3000 });
            if (!emailOperational) return this.toast.show({ severity: 'error', summary: 'Please Verify email Operational Otp', life: 3000 });
            if (!mobileAccounts) return this.toast.show({ severity: 'error', summary: 'Please Verify mobile Accounts Otp', life: 3000 });
            if (!mobileOperational) return this.toast.show({ severity: 'error', summary: 'Please verify mobile Operational Otp', life: 3000 });
        }

        this.props.sendDetails(org, verify);
        this.props.stepChange(this.props.currentTab + 1);
        this.props.currentTabChange(this.props.currentTab + 1);
    };

    checkIsSubDomainAvail = (subDomain) => {
        let url = `${baseUrlAdmin}/organization/domain/isavailable/${subDomain}`;

        if (subDomain && subDomain != '' && subDomain.length > 3) {
            this.setState({
                isDomainChecked: true,
                isLoading: true
            });

            this.service
                .get(url, true)
                .then((res) => {
                    if (res && res.res && res.res.isDomainAvaliable) {
                        this.setState({
                            isDomainAvaliable: true,
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            isDomainAvaliable: false,
                            isLoading: false
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: 'Some error occured.',
                        life: 3000
                    });
                    console.log(e);
                });
        } else {
            this.toast.show({
                severity: 'warn',
                summary: 'Invalid Sub domain',
                detail: 'Please enter sub domain more than 3 chars.',
                life: 3000
            });
        }
    };
    render() {
        const { org, formValidations, verify, isSubmitClick } = this.state;

        return (
            <div className="mx-5">
                <div>
                    <div>
                        <p className="text-2xl" style={{ fontWeight: 'bolder', color: 'black' }}>
                            Organization Details
                        </p>
                    </div>
                    <div className="grid mt-1">
                        <div className="col-6">
                            <p className="transport-fee-form-label">
                                Organization Details (Registration Name as ROC)<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'42vw'}
                                    value={org.name}
                                    // options={this.state.academicSession}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'name', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Organization Details"
                                />
                            </div>
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error text-lg">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                        <div className="col-6">
                            <p className="transport-fee-form-label">
                                Organization Title<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl "
                                    width={'42vw'}
                                    value={org.title}
                                    // options={this.state.academicSession}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'title', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Organization Title"
                                />
                            </div>
                            {formValidations && !formValidations.fields['title'].isValid && <p className="p-error text-lg">{formValidations.fields['title'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Year of ESTD<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl"
                                    width={'20vw'}
                                    value={org.yearOfESTD}
                                    options={getESTDYears()}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'yearOfESTD', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Year"
                                />
                            </div>
                            {formValidations && !formValidations.fields['yearOfESTD'].isValid && <p className="p-error text-lg">{formValidations.fields['yearOfESTD'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Country<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl "
                                    width={'20vw'}
                                    value={org.country}
                                    options={geCountries()}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'country', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Country"
                                />
                            </div>
                            {formValidations && !formValidations.fields['country'].isValid && <p className="p-error text-lg">{formValidations.fields['country'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                State<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl "
                                    width={'20vw'}
                                    value={org.state}
                                    options={states.map((state) => {
                                        return { label: state.name, value: state.id };
                                    })}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'state', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="State"
                                />
                            </div>
                            {formValidations && !formValidations.fields['state'].isValid && <p className="p-error text-lg">{formValidations.fields['state'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                City<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="name"
                                    optionValue="id"
                                    className="text-xl "
                                    width={'20vw'}
                                    options={cities.filter((city) => city.stateId === this.state.org.state)}
                                    value={org.city}
                                    //options={this.state.citiesinSelectState}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'city', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="City"
                                />
                            </div>
                            {formValidations && !formValidations.fields['city'].isValid && <p className="p-error text-lg">{formValidations.fields['city'].errorMsg}</p>}
                        </div>

                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                PinCode<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className="text-xl"
                                    width={'20vw'}
                                    value={org.pincode}
                                    onChange={(e) => onTextChange(e.target.value, 'pincode', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="PinCode"
                                    keyfilter={'int'}
                                />
                            </div>
                            {formValidations && !formValidations.fields['pincode'].isValid && <p className="p-error text-lg">{formValidations.fields['pincode'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Communication Address<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetTextArea placeholder="Communication Address" className="" width={680} value={org.address} onChange={(e) => onTextChange(e.target.value, 'address', this, formFields, org, formValidations, 'org', 'formValidations')} />
                            </div>
                            {formValidations && !formValidations.fields['address'].isValid && <p className="p-error text-lg">{formValidations.fields['address'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p className="text-2xl" style={{ fontWeight: 'bolder', color: 'black' }}>
                            Contact Details
                        </p>
                    </div>
                    <div className="grid mt-1">
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Mobile No-Operational<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    value={org.mobileOperational}
                                    // options={this.state.academicSession}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'mobileOperational', this, formFields, org, formValidations, 'org', 'formValidations');
                                        verify.mobileOperationalOtpSentCount = 0
                                    }}
                                    placeholder=""
                                    keyfilter={'int'}
                                    disabled={this.props.editOrg || verify.mobileOperational}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.mobileOperational}
                                    onClick={() => this.sendOTPClick('mobileOperational', org.mobileOperational)}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'white', color: 'blue' }}
                                >
                                    {verify.mobileOperationalOtpSentCount ? 'Resend OTP' : 'Send OTP'}
                                </Button>
                            </div>
                            {formValidations && !formValidations.fields['mobileOperational'].isValid && <p className="p-error text-lg">{formValidations.fields['mobileOperational'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Enter OTP<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    onChange={(e) => this.onChangeOTP('mobileOperational', e.target.value)}
                                    placeholder={this.props.editOrg ? 'OTP Verified' : 'Enter OTP'}
                                    keyfilter={'int'}
                                    value={verify['mobileOperationalOtp']}
                                    disabled={this.props.editOrg || verify.mobileOperational}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.mobileOperational}
                                    onClick={() => this.validateOTP('mobileOperational')}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'white', color: 'blue' }}
                                >
                                    Validate OTP
                                </Button>
                            </div>
                            {isSubmitClick && verify && !verify['mobileOperational'] && <p className="p-error">Verify OTP</p>}
                            {/* {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>} */}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Mobile No-Accounts<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    value={org.mobileAccounts}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'mobileAccounts', this, formFields, org, formValidations, 'org', 'formValidations');
                                        verify.mobileAccountsOtpSentCount = 0
                                    }}
                                    placeholder=""
                                    keyfilter={'int'}
                                    disabled={this.props.editOrg || verify.mobileAccounts}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.mobileAccounts}
                                    onClick={() => this.sendOTPClick('mobileAccounts', org.mobileAccounts)}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'white', color: 'blue' }}
                                >
                                    {verify.mobileAccountsOtpSentCount ? 'Resend OTP' : 'Send OTP'}
                                </Button>
                            </div>
                            {formValidations && !formValidations.fields['mobileAccounts'].isValid && <p className="p-error text-lg">{formValidations.fields['mobileAccounts'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Enter OTP<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    onChange={(e) => this.onChangeOTP('mobileAccounts', e.target.value)}
                                    placeholder={this.props.editOrg ? 'OTP Verified' : 'Enter OTP'}
                                    keyfilter={'int'}
                                    value={verify['mobileAccountsOtp']}
                                    disabled={this.props.editOrg || verify.mobileAccounts}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.mobileAccounts}
                                    onClick={() => this.validateOTP('mobileAccounts')}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'white', color: 'blue' }}
                                >
                                    Validate OTP
                                </Button>
                            </div>
                            {isSubmitClick && verify && !verify['mobileAccounts'] && <p className="p-error">Verify OTP</p>}
                            {/* {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>} */}
                        </div>
                    </div>
                    <div className="grid mt-1">
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Email ID-Operational<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full overflow-x-auto"
                                    width={'20vw'}
                                    value={org.emailOperational}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'emailOperational', this, formFields, org, formValidations, 'org', 'formValidations');
                                        verify.emailOperationalOtpSentCount = 0
                                    }}
                                    placeholder=""
                                    disabled={this.props.editOrg || verify.emailOperational}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.emailOperational}
                                    onClick={() => this.sendOTPClick('emailOperational', org.emailOperational, 'email')}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'white', color: 'blue' }}
                                >
                                    {verify.emailOperationalOtpSent ? 'Resend OTP' : 'Send OTP'}
                                </Button>
                            </div>
                            {formValidations && !formValidations.fields['emailOperational'].isValid && <p className="p-error text-lg">{formValidations.fields['emailOperational'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Enter OTP<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    onChange={(e) => this.onChangeOTP('emailOperational', e.target.value)}
                                    value={verify['emailOperationalOtp']}
                                    // options={this.state.academicSession}
                                    placeholder={this.props.editOrg ? 'OTP Verified' : 'Enter OTP'}
                                    disabled={this.props.editOrg || verify.emailOperational}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.emailOperational}
                                    onClick={() => this.validateOTP('emailOperational', 'email')}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'transparent', color: 'blue' }}
                                >
                                    Validate OTP
                                </Button>
                            </div>
                            {isSubmitClick && verify && !verify['emailOperational'] && <p className="p-error">Verify OTP</p>}
                            {/* {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>} */}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Email ID-Accounts<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    value={org.emailAccounts}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'emailAccounts', this, formFields, org, formValidations, 'org', 'formValidations');
                                        verify.emailAccountsOtpSentCount = 0
                                    }}
                                    placeholder=""
                                    disabled={this.props.editOrg || verify.emailAccounts}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.emailAccounts}
                                    onClick={() => this.sendOTPClick('emailAccounts', org.emailAccounts, 'email')}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'white', color: 'blue' }}
                                >
                                    {verify.emailAccountsOtpSentCount ? 'Resend OTP' : 'Send OTP'}
                                </Button>
                            </div>
                            {formValidations && !formValidations.fields['emailAccounts'].isValid && <p className="p-error text-lg">{formValidations.fields['emailAccounts'].errorMsg}</p>}
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                Enter OTP<span className="ma-required">*</span>
                            </p>
                            <div className="flex" style={{ position: 'relative' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    onChange={(e) => this.onChangeOTP('emailAccounts', e.target.value)}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    type={Number}
                                    placeholder={this.props.editOrg ? 'OTP Verified' : 'Enter OTP'}
                                    value={verify['emailAccountsOtp']}
                                    disabled={this.props.editOrg || verify.emailAccounts}
                                />
                                <Button
                                    disabled={this.props.editOrg || verify.emailAccounts}
                                    onClick={() => this.validateOTP('emailAccounts', 'email')}
                                    style={{ position: 'absolute', zIndex: 100, right: 6, top: 8, border: 0, backgroundColor: 'white', color: 'blue' }}
                                >
                                    Validate OTP
                                </Button>
                            </div>
                            {isSubmitClick && verify && !verify['emailAccounts'] && <p className="p-error">Verify OTP</p>}
                            {/* {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>} */}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p className="text-2xl" style={{ fontWeight: 'bolder', color: 'black' }}>
                            Website Details
                        </p>
                    </div>

                    <div className="grid mt-1">
                        <div className="col-6">
                            <p className="transport-fee-form-label">
                                Website<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={
                                        <div className="flex gap-1 align-items-center">
                                            <MailIcon width={24} height={24} color={'#667A85'} />
                                            <span className="text-xl">https://</span>
                                        </div>
                                    }
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'42vw'}
                                    value={org.website}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'website', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder=""
                                />
                            </div>
                            {formValidations && !formValidations.fields['website'].isValid && <p className="p-error text-lg">{formValidations.fields['website'].errorMsg}</p>}
                        </div>
                        <div className="col-6">
                            <p className="transport-fee-form-label">
                                SMS Client Website<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={
                                        <div className="flex gap-1 align-items-center">
                                            <MailIcon width={24} height={24} color={'#667A85'} />
                                            <span className="text-xl">https://</span>
                                        </div>
                                    }
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'42vw'}
                                    value={org.smsClientWebsite}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'smsClientWebsite', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder=""
                                />
                            </div>
                            {formValidations && !formValidations.fields['smsClientWebsite'].isValid && <p className="p-error text-lg">{formValidations.fields['smsClientWebsite'].errorMsg}</p>}
                        </div>
                        <div className="col-6">
                            <p className="transport-fee-form-label">
                                Email Client Website<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={
                                        <div className="flex gap-1 align-items-center">
                                            <MailIcon width={24} height={24} color={'#667A85'} />
                                            <span className="text-xl">https://</span>
                                        </div>
                                    }
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'42vw'}
                                    value={org.emailClientWebsite}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'emailClientWebsite', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder=""
                                />
                            </div>
                            {formValidations && !formValidations.fields['emailClientWebsite'].isValid && <p className="p-error text-lg">{formValidations.fields['emailClientWebsite'].errorMsg}</p>}
                        </div>
                        <div className="col-6">
                            <p className="transport-fee-form-label">
                                Sub Domain<span className="ma-required">*</span>
                            </p>
                            <div className="flex align-items-end gap-1">
                                <ClassetInputText
                                    icon={
                                        <div className="flex gap-1 align-items-center">
                                            <MailIcon width={24} height={24} color={'#667A85'} />
                                            <span className="text-xl">https://</span>
                                        </div>
                                    }
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'32vw'}
                                    value={org.subDomain}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'subDomain', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder=""
                                />
                                <p>.classet.in</p>
                                <Button onClick={() => this.checkIsSubDomainAvail(org.subDomain)}>Check</Button>
                            </div>
                            <>{this.state.org.subDomain.length > 3 && this.state.isDomainChecked && (this.state.isDomainAvaliable ? <span className="ma-success-msg">Available</span> : <span className="ma-error-msg">Not available</span>)}</>
                            {formValidations && !formValidations.fields['subDomain'].isValid && <p className="p-error text-lg">{formValidations.fields['subDomain'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p className="text-2xl" style={{ fontWeight: 'bolder', color: 'black' }}>
                            Documents
                        </p>
                    </div>
                    <div className="grid mt-1 gap-5 ml-1">
                        <div className="mt-3">
                            <FileUpload
                                id={'logoRectangle'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onLogoRectangleSuccess}
                                // Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid col-3"
                            >
                                <p className="transport-fee-form-label">
                                    Logo Rectangle<span className="ma-required">*</span>
                                </p>

                                <div className="flex flex-column items-center">
                                <ClassetInputText
                                    width={'20vw'}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={org.logoRectangle?.fileName}
                                    onChange={(e) => onTextChange(e.target.value, 'logoRectangle', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="Upload Photo"
                                    disabled={true}
                                />

                                    {(org.logoRectangle?.storageLocation || this.props.editUser?.org.logoRectangle?.storageLocation) && (
                                        <div className="ml-2 mt-2" >
                                            <img src={org.logoRectangle?.storageLocation || this.props.editUser?.org.logoRectangle?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                        </div>
                                    )}

                                </div>
                            </FileUpload>
                        </div>

                        <div className="mt-3">
                            <FileUpload
                                id={'logoSquare'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onLogoSquareSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid col-3"
                            >
                                <p className="transport-fee-form-label">
                                    Logo Square<span className="ma-required">*</span>
                                </p>

                                <div className="flex flex-column items-center">
                                <ClassetInputText
                                    width={'20vw'}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={org.logoSquare?.fileName}
                                    onChange={(e) => onTextChange(e.target.value, 'logoSquare', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="Upload Photo"
                                    disabled={true}
                                />

                                    {(org.logoSquare?.storageLocation || this.props.editUser?.org.logoSquare?.storageLocation) && (
                                        <div className="ml-2 mt-2" >
                                            <img src={org.logoSquare?.storageLocation || this.props.editUser?.org.logoSquare?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                        </div>
                                    )}

                                </div>
                            </FileUpload>
                        </div>

                        <div className="mt-3">
                            <p className="transport-fee-form-label flex alignt-items-center gap-3">
                                GST Applicable
                                <InputSwitch
                                    checked={org.gstApplicable === 'yes'}
                                    onChange={() => {
                                        this.setState((prevState) => ({
                                            org: {
                                                ...prevState.org,
                                                gstApplicable: prevState.org.gstApplicable === 'yes' ? 'no' : 'yes',
                                                gstNo: org.gstApplicable === 'yes' ? '' : prevState.org.gstNo,
                                                gstCertificate: org.gstApplicable === 'yes' ? {} : prevState.org.gstCertificate
                                            }
                                        }));
                                        if (org.gstApplicable === 'no' || org.gstApplicable === '') {
                                            formValidations.fields['gstNo'].isRequired = true
                                            formValidations.fields['gstCertificate'].isRequired = true
                                        } else {
                                            formValidations.fields['gstNo'].isRequired = false
                                            formValidations.fields['gstCertificate'].isRequired = false
                                            formValidations.fields['gstNo'].errorMsg = ' '
                                        }
                                    }}
                                />
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    value={org.gstNo}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'gstNo', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Enter GST No"
                                    disabled={org.gstApplicable === 'no' || org.gstApplicable === ''}
                                />
                            </div>
                            {formValidations && !formValidations.fields['gstNo'].isValid && <p className="p-error text-lg">{formValidations.fields['gstNo'].errorMsg}</p>}
                        </div>
                        <div className="mt-3">
                            <FileUpload
                                id={'gstCertificate'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onCertificateSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'pdfs'}
                                title={'pdf'}
                                disabled={org.gstApplicable === 'no' || org.gstApplicable === ''}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid col-3"
                            >
                                <p className="transport-fee-form-label">
                                    Upload GST Certificate
                                </p>
                                <ClassetInputText
                                    width={'20vw'}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={org?.gstCertificate?.fileName}
                                    onChange={(e) => onTextChange(e.target.value, 'gstCertificate', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="Upload PDF"
                                    disabled={true}
                                />
                            </FileUpload>
                        </div>
                    </div>
                    <div className="grid mt-1 gap-5 ml-1">
                        <div className="mt-3">
                            <p className="transport-fee-form-label">
                                Agreement Tenure<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    width={'20vw'}
                                    value={org.agreementTenure}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'agreementTenure', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Agreement Tenure"
                                />
                            </div>
                            {formValidations && !formValidations.fields['agreementTenure'].isValid && <p className="p-error text-lg">{formValidations.fields['agreementTenure'].errorMsg}</p>}
                        </div>
                        <div className="mt-3">
                            <FileUpload
                                id={'agreement'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onAgreementSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'pdfs'}
                                title={'pdf'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid col-3"
                            >
                                <p className="transport-fee-form-label">
                                    Upload Agreement<span className="ma-required">*</span>
                                </p>
                                <ClassetInputText
                                    width={'20vw'}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={org.agreement?.fileName}
                                    onChange={(e) => onTextChange(e.target.value, 'agreement', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="Upload Agreement"
                                    disabled={true}
                                />
                            </FileUpload>
                        </div>
                        <div className="mt-3">
                            <p className="transport-fee-form-label">
                                Payment Tenure<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetCalendar
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    width={250}
                                    calendarMode={'single'}
                                    id="tenureStartDate"
                                    value={org?.tenureStartDate ? new Date(org.tenureStartDate) : new Date()}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'tenureStartDate', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            {formValidations && !formValidations.fields['tenureStartDate'].isValid && <p className="p-error text-lg">{formValidations.fields['tenureStartDate'].errorMsg}</p>}
                        </div>
                        <div className="mt-3">
                            <p className="transport-fee-form-label">
                                Payment Tenure<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetCalendar
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    width={250}
                                    id="tenureEndDate"
                                    calendarMode={'single'}
                                    value={org?.tenureEndDate ? new Date(org.tenureEndDate) : new Date()}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'tenureEndDate', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="End Date"
                                    minDate={new Date(org.tenureStartDate)}
                                />
                            </div>
                            {formValidations && !formValidations.fields['tenureEndDate'].isValid && <p className="p-error text-lg">{formValidations.fields['tenureEndDate'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p className="text-2xl" style={{ fontWeight: 'bolder', color: 'black' }}>
                            ID Card Images
                        </p>
                    </div>
                    <div className="grid mt-1 gap-5 ml-1">
                        <div className="mt-3">
                            <FileUpload
                                id={'idCardBanner'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onBannerImageSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid col-3"
                            >
                                <p className="transport-fee-form-label">
                                    Banner Image<span className="ma-required">*</span>
                                </p>


                                <div className="flex flex-column items-center">
                                <ClassetInputText
                                    width={'20vw'}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={org.idCardBanner?.fileName}
                                    onChange={(e) => onTextChange(e.target.value, 'idCardBanner', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="Upload Photo"
                                    disabled={true}
                                />

                                    {(org.idCardBanner?.storageLocation || this.props.editUser?.org.idCardBanner?.storageLocation) && (
                                        <div className="ml-2 mt-2" >
                                            <img src={org.idCardBanner?.storageLocation || this.props.editUser?.org.idCardBanner?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                        </div>
                                    )}

                                </div>
                            </FileUpload>
                        </div>

                        <div className="mt-3">
                            <FileUpload
                                id={'idCardSignature'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onSignatureImageSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid col-3"
                            >
                                <p className="transport-fee-form-label">
                                    Signature Image<span className="ma-required">*</span>
                                </p>
                                <div className="flex flex-column items-center">
                                <ClassetInputText
                                    width={'20vw'}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={org.idCardSignature?.fileName}
                                    onChange={(e) => onTextChange(e.target.value, 'idCardSignature', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="Upload Photo"
                                    disabled={true}
                                />
                                    {(org.idCardSignature?.storageLocation || this.props.editUser?.org.idCardSignature?.storageLocation) && (
                                        <div className="ml-2 mt-2" >
                                            <img src={org.idCardSignature?.storageLocation || this.props.editUser?.org.idCardSignature?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                        </div>
                                    )}
                                </div>
                            </FileUpload>
                        </div>

                        <div className="mt-3">
                            <FileUpload
                                id={'idCardWatermark'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onWaterMarkSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid"
                            >
                                <p className="transport-fee-form-label">
                                    Watermark Image<span className="ma-required">*</span>
                                </p>
                                <div className="flex flex-column items-center">
                                <ClassetInputText
                                    width={'20vw'}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={org.idCardWatermark?.fileName}
                                    onChange={(e) => onTextChange(e.target.value, 'idCardWatermark', this, formFields, org, formValidations, 'org', 'formValidations')}
                                    placeholder="Upload Photo"
                                    disabled={true}
                                />
                                    {(org.idCardWatermark?.storageLocation || this.props.editUser?.org.idCardWatermark?.storageLocation) && (
                                        <div className="ml-2 mt-2" >
                                            <img src={org.idCardWatermark?.storageLocation || this.props.editUser?.org.idCardWatermark?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                        </div>
                                    )}
                                </div>
                            </FileUpload>
                        </div>
                        <div className="col-3">
                            <p className="transport-fee-form-label">
                                QR Code<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl"
                                    width={'20vw'}
                                    value={org.idCardQrCode}
                                    options={geQRCodeTypes()}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'idCardQrCode', this, formFields, org, formValidations, 'org', 'formValidations');
                                    }}
                                    placeholder="Code Generation Type"
                                />
                            </div>
                            {formValidations && !formValidations.fields['idCardQrCode'].isValid && <p className="p-error text-lg">{formValidations.fields['idCardQrCode'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <div className="text-center mt-8">
                    <Button label="Save and Proceed to next" className="confirmDialogAcceptBtn px-6 mr-5 w-30rem" onClick={this.createOrganization} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" style={{}} onClick={() => this.props.setCreateOrEdit()} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}
export default withRouter(Organization);
