import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { AddIconInButtonFull, AddUserIcon, ClassetEditIcon, CodeLinkIcon, EditIcon, InputIcon, MailIcon, MoneyIcon } from '../svgIcons';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import InputTextB from '../customComponents/inputTextB';
import { FIELDS_INFO, paymentModes } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import FeeStructure from './FeeStructure/feeStructure';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../utile/formHelper';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../store/apiConstants';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import Service from '../../services';
import admissionCategory from '../admissionNewManagement/admissionCategory';
import feeCategory from '../feeSetUp/FeeCatagory/feeCategory';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BranchToSectionDD from '../BaseDropdownComponents/BranchToSectionDD';



const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 2, label: "sample 2" },
    { value: 3, label: "sample 3" },
    { value: 4, label: "sample 4" },
    { value: 5, label: "sample 5" },
]
const payInAmtOrPerOptions = [
    { label: "Amount", value: "amt" },
    { label: "Percentage", value: "per" }
]

const feeConcessionTypesOptions = [
    { value: 'merit', label: 'Merit-based' },
    { value: 'sports', label: 'Sports' },
    { value: 'financial', label: 'Financial Aid' },
    { value: 'alumni', label: 'Alumni' },
    { value: 'staff', label: 'Staff Child' }
];

const createFeeFields = require('./feeConcession.json')

class FeeConcession extends Component {

    constructor(props) {

        super(props);
        this.formFields = getFormFields(createFeeFields);
        this.state = {
            fee: this.formFields.data,
            formValidations: this.formFields.formValidations,
            currentTab: 0,
            concessions: [],
            admissionCategoryOptions: [],
            isDialogOpenForCreateConcession: false,
            feeconcessionStatusObj: {},
            feeTypeOptions: [],
            feeTypetotalOptions: [],
            isStatusChangeDialog: false,
            categoryAssignInfo: [],
            selectedCategoryAssignInfo: [],
            selectAll: false,
            branchId: "",
            boardId: "",
            classId: "",
            sectionId: "",
            feeCategoryOptions: [],
            feeStructureDialog: false,
            newConcession: {}
        };
        this.service = new Service();


    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }


    componentDidMount() {
        this.getDropDownoptionsApi();
    }




    getDropDownoptionsApi = () => {
        this.getFeeConcessionsData();
        this.getAdmissionCategoryOptions();
        this.getFeeCategoryOptions();
        this.getfeeTypeOptions();
    }



    getFeeConcessionsData = async () => {
        this.setState({ isLoading: true })
        const year = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/concession?academicYear=${year}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ concessions: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Errors In Fetching Data', detail: "", life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false },
                () => this.toast.show({ severity: 'error', summary: 'Errors In Fetching Data', detail: "", life: 3000 })
            );

        }

    };



    getfeeTypeOptions = async () => {
        this.setState({ isLoading: true })
        const year = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fee-types?academicYear=${year}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.status && res?.res?.data?.response) {
                this.setState({ feeTypetotalOptions: res?.res?.data?.response, isLoading: false });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Errors In Fetching Data', detail: "", life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false })
            this.setState({ isLoading: false }, () => this.toast.show({ severity: 'error', summary: 'Errors In Fetching Data', detail: "", life: 3000 }));

        }
    }


    getFeeCategoryOptions = async () => {
        this.setState({ isLoading: true })
        const year = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fee-category?academicYear=${year}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.status && res?.res?.data?.response) {
                this.setState({ feeCategoryOptions: res?.res?.data?.response, isLoading: false });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Errors In Fetching Data', detail: "", life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Errors In Fetching Data', detail: "", life: 3000 });
        }
    }


    getAdmissionCategoryOptions = () => {
        this.setState({ isLoading: true })
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
        this.service.get(url,  true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each?.categoryName,
                    value: each?._id
                }
            })
            if (data?.status && data?.res?.data) {
                this.setState({ admissionCategoryOptions: options,isLoading: false  })
            } else {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the Admission category options', life: 3000 });
            }
        })
    }

    onSelectionChange = (event) => {
        const value = event.value;
        let selectedAll = (value.length === this.state.categoryAssignInfo.length)
        this.setState({
            selectedCategoryAssignInfo: event.value,
            selectAll: selectedAll

        })
    }


    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedCategoryAssignInfo: this.state.categoryAssignInfo,
                selectAll: true
            })
        } else {
            this.setState({
                selectedCategoryAssignInfo: [],
                selectAll: false
            })
        }
    };

    onHideAssignDialog = () => {
        this.setState({
            feeStructureDialog: false,
            categoryAssignInfo: [],
            selectedCategoryAssignInfo: [],
            selectAll: false
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if ((prevState.fee?.feeCategories != this.state.fee?.feeCategories) || (prevState.fee?.admissionCategories != this.state.fee?.admissionCategories)) {
            const selectedCategory = this.state.feeCategoryOptions?.filter(each => each._id === this.state.fee?.feeCategories)
            const feetypesData = this.state.feeTypetotalOptions?.filter(each => {
                if (selectedCategory[0]?.feeTypes?.includes(each?._id)) {
                    return each
                }
            })
            const filteredFeetypes = feetypesData?.filter(eachtype => {
                return this.state.fee?.admissionCategories?.some(each => eachtype?.admissionType?.includes(each))
            });
            this.setState({ feeTypeOptions: filteredFeetypes });
        }
    }


    feeStructureAssign = () => {
        
        let inputKey = this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId';


        if (this.state.selectedCategoryAssignInfo?.length) {
            let assignInfo = this.state.selectedCategoryAssignInfo?.map((each) => {
                return {
                    [inputKey]: each[inputKey]
                }
            });
            let payload = {
                "branchId": this.state.dropdownsData.branchId,
                "boardId": this.state.dropdownsData.boardId,
                "classId": this.state.dropdownsData.classId,
                "sectionId": this.state.dropdownsData.sectionId,
                "academicYear": localStorage.getItem("userAcademicYear"),
                "feeTypes": this.state.feeconcessionStatusObj?.feeTypes,
                "concessionId": this.state.feeconcessionStatusObj?._id,
                "type": "CON",
                "assignedInfo": assignInfo
            }
            this.setState({ isLoading: true })
            let url = `${baseUrlAdmin}/concession/assign`

            this.service.post(url, payload, true).then(res => {
                if (res?.status && res?.res?.status) {

                    this.setState({
                         isLoading: false,
                        feeStructureDialog: false,
                        feeStructureDetails: '',
                        categoryAssignInfo: [],
                        selectedCategoryAssignInfo: [],
                        selectAll: false

                    }, () => { this.getFeeConcessionsData(); });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(err => {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
            })


        }
        else {
            this.toast.show({ severity: 'error', summary: `Assign Records shouldn't not empty`, detail: "Please select Atleast one record", life: 3000 });
        }


    }
    onCreateFeeConcession = async () => {
       
        const { boardsList, editAdmissionFeeType, fee } = this.state
        let body = cloneDeep(fee)
        const formStatus = isFormValid(createFeeFields, this.formFields.formValidations, body);
        let isCompleteFormValid = true
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, dateError: true });
            isCompleteFormValid = false;
        }
        
        if (isCompleteFormValid) {
            const payload =
            {
                "name": body?.concessionName,
                "concessionType": body?.concessionType,
                "isAmtOrPerType": body?.amountPercentage, //per
                "amtOrPer": body?.concessionAmount,
                "paymentMode": body?.paymentMode,
                "admissionCategories": body?.admissionCategories,
                "feeCategories": body?.feeCategories,
                "feeTypes": body?.studentFeeType,
                "academicYear": localStorage.getItem("userAcademicYear"),
                "status": fee?.status,
                "type": "CON"

            }
      
            const data = fee?._id ? { ...payload, _id: fee?._id } : payload
            let url = `${baseUrlAdmin}/concession/`;
            this.setState({ isLoading: true })
            try {
                const res = await this.service.post(url, data, true);
                if (res?.res?.status && res.status) {
                    this.setState({ isDialogOpenForCreateConcession: false, fee: {}, isLoading: false, });
                    this.getFeeConcessionsData();
                    this.toast.show({ severity: 'success', summary: `Fee Concession has been ${fee?._id ? "updated" : "created"} successfully`, detail: "", life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Error', detail: `Error in ${fee?._id ? "update" : "create"} request`, life: 3000 });

                }
            } catch (e) {
                this.setState({ isLoading: false })
                console.log(e);
                this.toast.show({ severity: 'error', summary: 'Error', detail: `Error in ${fee?._id ? "update" : "create"} request`, life: 3000 });
            }
        }
    }


    handleChangeTheStatusFeeConcession = async () => {
        this.setState({ isLoading: true })
        const { _id, isActiveStatus } = this.state.feeconcessionStatusObj;
        let url = `${baseUrlAdmin}/concession/${_id}/status`;
        try {
            const res = await this.service.put(url, { "status": isActiveStatus }, true);
            if (res?.res?.status && res.status) {
                this.setState({ isStatusChangeDialog: false, feeconcessionStatusObj: {} });
                this.getFeeConcessionsData();
                this.toast.show({ severity: 'success', summary: 'Fee Concession Status has been updated successfully', detail: "", life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Error in update Status request', detail: "", life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Error in update Status request', detail: "", life: 3000 });
        }
    };


    setDropdownValues = (dropdownsData) => {
        console.log(dropdownsData, "ddddd")
        this.setState({
            dropdownsData,
        }, () => { this.getFeeCategoryAssignInfo(dropdownsData?.branchId, dropdownsData?.boardId, dropdownsData?.classId, dropdownsData?.sectionId) })


    }


    getFeeCategoryAssignInfo = (branchId, boardId, classId, sectionId) => {
        
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let payload = {
            "branchId": branchId,
            "boardId": boardId,
            "classId": classId,
            "sectionId": sectionId

        }
        payload.academicYear = AcademicYear
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/fee-structure/assignInfo`;
        this.service.post(url, payload, true).then(data => {
            if (data?.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    categoryAssignInfo: data?.res?.data,
                    isLoading: false

                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });
            }
        });

    }

    handleEditFeeConcession = (fee, index) => {
        let filtertypes = [];
        this.state.feeTypeOptions?.filter(eachType => {
            if (fee?.feeTypes?.includes(eachType._id)) {
                return filtertypes.push(eachType._id)
            }
        })
        const editObj = {
            ...fee,
            concessionName: fee?.name,
            concessionType: fee?.concessionType,
            amountPercentage: fee?.isAmtOrPerType,
            concessionAmount: fee?.amtOrPer,
            paymentMode: fee?.paymentMode,
            admissionCategories: fee?.admissionCategories,
            feeCategories: fee?.feeCategories,
            studentFeeType: fee?.feeTypes,
        }

        this.setState({
            fee: editObj,
            isDialogOpenForCreateConcession: true
        })

    }



    confirm1Change = (e, fee) => {
        this.setState({ isStatusChangeDialog: true, feeconcessionStatusObj: { ...fee, isActiveStatus: e.target.value } })
    };

    addNewConcession = () => {
        this.setState(prevState => ({
            concessions: [...prevState.concessions, { id: Date.now(), label: 'New Concession', description: 'Description here' }]
        }));
    }

    handleChange = (field, value) => {
        this.setState(prevState => ({
            newConcession: {
                ...prevState.newConcession,
                [field]: value
            }
        }));
    }

    render() {
        const buttonStyle = {
            fontFamily: 'Inter',
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '24px',
            color: "white",
            borderRadius: "8px",
            letterSpacing: '0.25px',
            textAlign: 'left',
            background: 'var(--components-button-secondary-background-default, #076EFF)',
            padding: '5px 25px',
            cursor: 'pointer'
        };
        const { fee, isShowUserInfo, isShowAnalytics, editUser, group, branch, formValidations, newConcession } = this.state



        return (
            <div>
                <div className="admin-management">
                    <div style={{ marginBottom: "110px" }}>

                        <div className="ml-5 grid h-4rem">
                            <div className="mt-3 md:col-12 lg:col-12 xl:col-12">
                                <div className="grid mb-6">
                                    <Authorizer permId={PERMISSIONS.CREATE_FEE_CONCESSION} >
                                        <div className='col-11 flex justify-content-end align-items-center'>
                                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={() => { this.setState({ isDialogOpenForCreateConcession: true }) }}>
                                                <p className=' text-xl'>New Concession</p>
                                                <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                                            </Button>
                                        </div>
                                    </Authorizer>
                                </div>
                                <div className='grid doubts-tabs'>
                                    {
                                        this.state.concessions.map((fee, index) => {
                                            return <div key={index}
                                                className="mx-6 card cursor-pointer col-3 doubts-tab-w"
                                                style={this.state.currentTab === index ? { height: '170px', width: '320px' } : { height: '170px', width: '320px' }}
                                            >
                                                <div className="flex flex-column justify-content-center align-items-center ml-3">
                                                    <Authorizer permId={PERMISSIONS.UPDATE_FEE_CONCESSION} >
                                                        <div className='mt-1 w-full flex justify-content-end align-items-center'>
                                                            <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center' onClick={() => this.handleEditFeeConcession(fee, index)}>
                                                                <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                            </div>
                                                            <div className="">
                                                                <InputSwitch
                                                                    className='mt-1'
                                                                    onChange={(e) => this.confirm1Change(e, fee)}
                                                                    checked={fee?.status} />
                                                            </div>
                                                        </div>
                                                    </Authorizer>
                                                    <div className="w-full flex mt-4  ml-3">
                                                        <h3 className='Montserrat24 surface-overlay white-space-nowrap overflow-hidden text-overflow-ellipsis'>{fee?.name}</h3>
                                                    </div>
                                                    <div className="flex justify-content-center mr-5">
                                                        <p className='poppins24' style={{ fontSize: '24px' }}>{fee?.studentsCount || 0} Students</p>
                                                    </div>
                                                    <Authorizer permId={PERMISSIONS.ASSIGN_FEE_CONCESSION} >
                                                        <div style={{ marginLeft: 'auto' }} onClick={() => { this.setState({ feeStructureDialog: true, feeconcessionStatusObj: fee }) }} className='-mt-5 d-flex  mr-3 justify-content-end align-items-center'>
                                                            <AddUserIcon width={32} height={32} color={'#000000'} />
                                                        </div>
                                                    </Authorizer>


                                                </div>
                                            </div>
                                        })
                                    }
                                </div>


                            </div>
                        </div>
                    </div>

                </div>

                <Dialog
                    visible={this.state.feeStructureDialog}
                    draggable={false}
                    closeOnEscape={false}
                    // position={"right"}
                    // className='ma-alert-box w-3 mr-0'
                    style={{ width: "1400px", height: "800px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="text-center text-black-alpha-90 font-bold text-3xl">Fee Structure & Schedule</h4>
                        </div>)
                    }}
                    // footer={() => (
                    //     <div className="text-center mt-4">
                    //         <Button label='Assign ' className='confirmDialogAcceptBtn  mr-5 w-22rem' onClick={this.feeStructureAssign} />
                    //         <Button label="Cancel" className='confirmDialogCancelBtn px-7' onClick={this.onHideAssignDialog} />
                    //     </div>
                    // )}
                    onHide={() => this.setState({ feeStructureDialog: false })}
                >
                    <div className='ma-scroll-vr-smoke'>
                        <BranchToSectionDD setValues={this.setDropdownValues} />
                        <div className="p-5">
                            <DataTable ref={(el) => this.dt = el}
                                value={this.state.categoryAssignInfo}
                                dataKey={this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId'}
                                paginator
                                rows={10}
                                // showGridlines
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                // // globalFilter={this.state.globalFilter}
                                // responsiveLayout="scroll"
                                // // header={header}
                                sortable
                                selection={this.state?.selectedCategoryAssignInfo}
                                onSelectionChange={this.onSelectionChange}
                                selectAll={this.state.selectAll}
                                onSelectAllChange={this.onSelectAllChange}
                                selectionMode="checkbox">
                                <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "5rem", height: "5rem" }} alignHeader={"center"} selectionMode="multiple" exportable={false} />
                                <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="branchName" header="Branch" className="datatable-cel" sortable />
                                <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="boardName" header="Board" className="datatable-cel" sortable />
                                <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="className" header="Grade" className="datatable-cel" sortable />
                                {this.state?.dropdownsData?.classId ? <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="sectionName" header="Section Name" className="datatable-cel" sortable /> : <></>}
                                {!this.state?.dropdownsData?.sectionId ? <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="totalStudents" header="Total Students" className="datatable-cel" sortable /> : <></>}
                                {/* {!this.state?.dropdownsData?.sectionId && <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "17rem", height: "5rem" }} alignHeader={"center"} field="totalStudents" header="Unassigned Students" className="datatable-cel" sortable />} */}
                                {this.state?.dropdownsData?.sectionId ? <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="username" header="Students" className="datatable-cel" sortable /> : <></>}

                            </DataTable>
                        </div >
                        <div className="text-center mt-4">
                            <Button label='Assign ' className='confirmDialogAcceptBtn  mr-5 w-22rem' onClick={this.feeStructureAssign} />
                            <Button label="Cancel" className='confirmDialogCancelBtn px-7' onClick={this.onHideAssignDialog} />
                        </div>
                    </div>

                </Dialog>

                <Dialog
                    visible={this.state.isDialogOpenForCreateConcession}
                    draggable={false}
                    closeOnEscape={false}
                    position={"right"}
                    className='ma-alert-box'
                    style={{
                        width: "90%",
                        maxWidth: "410px",
                        height: "auto",
                        maxHeight: "90%",
                        // margin: "0 auto"
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="text-center text-black-alpha-90 font-bold">{`${this.state.fee?._id ? "Update" : "Add New"} Concession`} </h4>

                        </div>)
                    }}
                    onHide={() => this.setState({ isDialogOpenForCreateConcession: false, fee: {} })}
                >
                    <div className='flex flex-column justify-content-center mx-3'>

                        <div className="field">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Concession Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                    id="concessionName"
                                    className='border-round-md   border-none'
                                    value={fee.concessionName}
                                    onChange={(e) => { onTextChange(e.target.value, 'concessionName', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                    placeholder="Concession Name" />
                            </div>
                            {formValidations && !formValidations.fields['concessionName'].isValid && <p className="p-error text-lg ">{formValidations.fields['concessionName'].errorMsg}</p>}
                        </div>
                        <div className="field ">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Concession Type<span className='ma-required'>*</span></p>

                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    options={feeConcessionTypesOptions}
                                    optionLabel="label"
                                    optionValue="value"
                                    className=' no-border text-xl'
                                    value={fee.concessionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'concessionType', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                    placeholder={<p className=''>Select Concession Type</p>}

                                />
                            </div>
                            {formValidations && !formValidations.fields['concessionType'].isValid && <p className="p-error text-lg">{formValidations.fields['concessionType'].errorMsg}</p>}
                        </div>

                        <div className="field ">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Amount/Percentage<span className='ma-required'>*</span></p>

                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    options={payInAmtOrPerOptions}
                                    optionLabel="label"
                                    className=' no-border '
                                    value={fee.amountPercentage}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'amountPercentage', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                    placeholder={<p className=''>Select Amount/Percentage</p>}
                                />
                            </div>
                            {formValidations && !formValidations.fields['amountPercentage'].isValid && <p className="p-error text-lg">{formValidations.fields['amountPercentage'].errorMsg}</p>}
                        </div>

                        <div className="field">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Concession Amount/Percentage<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<CodeLinkIcon height={24} />}
                                    id="concessionAmount"
                                    className='border-round-md   border-none '
                                    value={fee.concessionAmount}
                                    onChange={(e) => { onTextChange(e.target.value, 'concessionAmount', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                    placeholder="Concession Amount/Percentage" />
                            </div>
                            {formValidations && !formValidations.fields['concessionAmount'].isValid && <p className="p-error text-lg ">{formValidations.fields['concessionAmount'].errorMsg}</p>}
                        </div>

                        <div className="field ">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Payement Mode<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    options={paymentModes}
                                    optionValue="value"
                                    optionLabel="label"
                                    className=' no-border '
                                    value={fee.paymentMode}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'paymentMode', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                    placeholder={<p className=''>Select Payment Modes</p>}
                                />
                            </div>
                            {formValidations && !formValidations.fields['paymentMode'].isValid && <p className="p-error text-lg">{formValidations.fields['paymentMode'].errorMsg}</p>}
                        </div>

                        <div className="field ">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Admission Categories<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetMultiSelect
                                    icon={<MoneyIcon width={24} height={24} color={'#667A85'} />}
                                    options={this.state.admissionCategoryOptions}
                                    optionLabel="label"
                                    optionValue="value"
                                    className=' no-border '
                                    value={fee.admissionCategories}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'admissionCategories', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                    placeholder={<p className=''>Select Admission Category</p>}
                                />
                            </div>
                            {formValidations && !formValidations.fields['admissionCategories'].isValid && <p className="p-error text-lg">{formValidations.fields['admissionCategories'].errorMsg}</p>}
                        </div>
                        {fee.admissionCategories?.length ?
                            <>
                                <div className="field ">
                                    <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Fee Categories<span className='ma-required'>*</span></p>
                                    <div className='flex'>
                                        <ClassetDropdown
                                            icon={<MoneyIcon width={24} height={24} color={'#667A85'} />}
                                            options={this.state.feeCategoryOptions}
                                            optionLabel="categoryName"
                                            optionValue="_id"
                                            className=' no-border text-xl'
                                            value={fee.feeCategories}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'feeCategories', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                            placeholder={<p className=''>Select Fee Category</p>}

                                        />
                                    </div>
                                    {formValidations && !formValidations.fields['feeCategories'].isValid && <p className="p-error text-lg">{formValidations.fields['feeCategories'].errorMsg}</p>}
                                </div>

                                <div className="field ">
                                    <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Student Fee Type<span className='ma-required'>*</span></p>
                                    <div className='flex'>
                                        <ClassetMultiSelect
                                            icon={<MoneyIcon width={24} height={24} color={'#667A85'} />}
                                            options={this.state.feeTypeOptions}
                                            optionLabel="feeType"
                                            optionValue="_id"
                                            className=' no-border '
                                            value={fee.studentFeeType}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'studentFeeType', this, createFeeFields, fee, formValidations, 'fee', 'formValidations') }}
                                            placeholder={<p className=''>Select Student Fee Type</p>}
                                        />
                                    </div>
                                    {formValidations && !formValidations.fields['studentFeeType'].isValid && <p className="p-error text-lg">{formValidations.fields['studentFeeType'].errorMsg}</p>}
                                </div>
                            </>
                            :
                            ""
                        }

                        <div className="mt-3" style={{ display: 'flex', }}>
                            <Button className='mr-2' style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => this.onCreateFeeConcession()}>
                                <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                                <p className='text-xl ml-2'>{`${this.state.fee?._id ? "Update" : "Add"}`}</p>
                            </Button>
                            <Button className='border-none' style={{ borderRadius: 10, backgroundColor: '#E0E0E0', color: "black", width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.setState({ isDialogOpenForCreateConcession: false, fee: {} }) }}>
                                <p className=' text-xl'>Cancel</p>
                            </Button>
                        </div>

                    </div>
                </Dialog>
                <Dialog
                    header={<div className='flex justify-content-center align-items-center'>
                        <i className="pi pi-exclamation-triangle text-center text-7xl text-red-400"></i>
                    </div>}
                    visible={this.state.isStatusChangeDialog}
                    style={{ width: '400px', height: "350px" }}
                    onHide={() => { this.setState({ isStatusChangeDialog: false, feeconcessionStatusObj: {} }) }}>
                    <div className="flex flex-column align-items-center surface-overlay border-round">
                        <h4 className="font-bold text-2xl block mb-2 mt-4" >
                            Confirmation ?
                        </h4>
                        <p className="mb-0 text-base">
                            {`Are you sure you want to Change The status `}
                            <span className='text-color text-base font-semibold'> {this.state.feeconcessionStatusObj.isActiveStatus ? "InActive to Active" : "Active to InActive"} </span>For the
                            <span className='p-error text-xl mt-1 font-semibold'>{` ${this.state.feeconcessionStatusObj.name}`}</span>?
                        </p>
                        <div className="flex align-items-center gap-2 mt-4">
                            <Button
                                label="Change"
                                onClick={() => { this.handleChangeTheStatusFeeConcession() }}
                                className="confirmDialogAcceptBtn"
                            ></Button>
                            <Button
                                label="Cancel"
                                outlined
                                onClick={() => { this.setState({ isStatusChangeDialog: false, feeconcessionStatusObj: {} }) }}
                                className="confirmDialogCancelBtn"
                            ></Button>
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {(this.state.isLoading) && <LoadingComponent />}

            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(FeeConcession)));