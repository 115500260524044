import React, { Component } from 'react';
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { BoardsIconInActive, GradesIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../store/apiConstants';

class BranchToSectionApprovalHierarchy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardIds: null,
            classIds: null,
            branchIds: null,
            sectionId: null,
            boards: [],
            classes: [],
            sections: [],
            heirarchy: [],
            branchesDD: [],
            isLoading: false
        };
        this.service = new Service();
        this.toast = React.createRef();
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.shouldUpdateState(prevState)) {
            this.updateStateWithFilteredData();
        }
    }

    shouldUpdateState(prevState) {
        return this.state.branchIds == null && this.props.boards?.length > 0 && this.props.branchData?.length > 0 && this.state?.heirarchy?.branchIds?.length && !prevState.branchIds;
    }

    getData = () => {
        this.getUserAssignedHeirarchy();
        this.props.getBranchesLatest();
        this.props.getBoardsData(true);
    };

    getUserAssignedHeirarchy = () => {
        this.setState({ isLoading: true });
        const url = `${baseUrlAdmin}/settings-approvals/userAssignedBoards?approvalType=${this.props.approvalType}`;

        this.service
            .get(url, true)
            .then((res) => {
                if (res?.status && res?.res?.status) {
                    this.setState({
                        heirarchy: { branchIds: res?.res?.data?.data?.map((d) => d.branchId) || [], adminLevels: res?.res?.data?.adminUserAssignedLevelDetails || [] },

                        isLoading: false
                    });
                } else {
                    this.setState({
                        heirarchy: { branchIds: [] },
                        isLoading: false
                    });
                }
            })
            .catch((e) => {
                this.setState({ isLoading: false });
                this.toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch hierarchy data', life: 3000 });
            });
    };

    updateStateWithFilteredData() {
        const { boards, branchData } = this.props;
        const { heirarchy } = this.state;

        const branchess = branchData.filter((item) => heirarchy?.branchIds?.includes(item.key));
        const branchdIds = branchess.map((e) => e.key);

        const filteredBoards = this.getFilteredBoards(boards, branchdIds);

        if (filteredBoards.length > 0) {
            this.processFilteredBoards(filteredBoards, branchdIds);
        }
    }

    getFilteredBoards(boards, branchIds) {
        return cloneDeep(
            boards.filter((board) => {
                return board.assignedBranches.some((branchId) => branchIds.includes(branchId));
            })
        );
    }

    processFilteredBoards(filteredBoards, branchIds) {
        this.formatBoardData(filteredBoards);

        const boardIds = filteredBoards.map((e) => e.boardId);
        const filterClasses = this.getFilteredClasses(filteredBoards, boardIds[0]);
        const filterClassIds = filterClasses.map((e) => e.classId);
        const filteredSections = this.getFilteredSections(filterClasses, filterClassIds[0]);

        this.setNewState(filteredBoards, filterClasses, filteredSections, boardIds, filterClassIds, branchIds);
    }

    formatBoardData(boards) {
        boards.forEach((board) => {
            board?.classes?.forEach((classs) => {
                classs.className = classs.className?.includes('--') ? classs.className : `${board?.boardName} -- ${classs.className}`;
                classs.sections.forEach((sec) => {
                    sec.sectionName = sec.sectionName.includes('--') ? sec.sectionName : `${classs.className} -- ${sec.sectionName}`;
                });
            });
        });
    }

    getFilteredClasses(boards, boardId) {
        return boards
            ?.filter((board) => board.boardId === boardId)
            ?.map((e) => e.classes)
            .flat();
    }

    getFilteredSections(classes, classId) {
        return classes
            .filter((e) => e.classId === classId)
            ?.map((e) => e.sections)
            .flat();
    }

    setNewState(filteredBoards, filterClasses, filteredSections, boardIds, filterClassIds, branchIds) {
        const sectionIds = filteredSections?.map((e) => e.sectionId);
        const selectedSectionName = filteredSections.length > 0 ? filteredSections[0].sectionName : '';
        const selectedSectionId = sectionIds?.length > 0 ? sectionIds[0] : '';

        this.setState(
            {
                branches: filterClasses[0].branches,
                branchIds: [branchIds[0]],
                boards: filteredBoards,
                boardIds: [boardIds[0]],
                classes: filterClasses,
                classIds: [filterClassIds[0]],
                selectedClassName: filterClasses[0].className,
                selectedbranchName: filterClasses[0].branches?.[0]?.branchName,
                sections: filteredSections,
                sectionId: selectedSectionId,
                selectedsectionName: selectedSectionName,
                branchesDD: this.props.branchData.filter((item) => this.state?.heirarchy?.branchIds.includes(item.key))
            },
            this.setDropdownsData
        );
    }

    onChangeBranch = (branchIds) => {
        const filteredBoards = this.getFilteredBoards(this.props.boards, branchIds);
        this.formatBoardData(filteredBoards);

        this.setState(
            {
                branchIds,
                boards: filteredBoards,
                classes: [],
                boardIds: '',
                classIds: '',
                branches: [],
                sections: [],
                sectionId: '',
                selectedsectionName: ''
            },
            this.setDropdownsData
        );
    };

    onChangeBoard = (boardIds) => {
        const filteredClasses = this.state.boards.filter((board) => boardIds.includes(board.boardId)).flatMap((board) => board.classes);

        this.setState(
            {
                classes: filteredClasses,
                boardIds,
                classIds: '',
                branches: [],
                sections: [],
                sectionId: '',
                selectedsectionName: ''
            },
            this.setDropdownsData
        );
    };

    onChangeClass = (classIds) => {
        const filterClasses = this.state.classes?.filter((classs) => classIds?.includes(classs?.classId));
        const sections = filterClasses?.flatMap((fClass) => fClass.sections?.filter((sec) => this.state.branchIds?.includes(sec?.branchId)));

        this.setState(
            {
                classIds,
                sections,
                sectionId: '',
                selectedsectionName: ''
            },
            this.setDropdownsData
        );
    };

    onChangeSection = (sectionId) => {
        const selectedSection = this.state.sections?.find((section) => section.sectionId === sectionId);
        const selectedsectionName = selectedSection?.sectionName || '';

        this.setState(
            {
                sectionId,
                selectedsectionName
            },
            this.setDropdownsData
        );
    };

    setDropdownsData = () => {
        const { boardIds, classIds, branchIds, sectionId, heirarchy } = this.state;
        this.props.setValues({
            boardIds,
            classIds,
            branchIds,
            sectionId,
            heirarchy
        });
    };

    render() {
        const { branchesDD, branchIds, boards, boardIds, classes, classIds, sections, sectionId, isLoading } = this.state;
        const { selectedValue } = this.props;
        const isDisabled = selectedValue === 2;

        return (
            <div>
                <div>
                    <div className="grid m-2">
                        <div className="col-3">
                            <ClassetMultiSelect
                                required={true}
                                label="Branch"
                                icon={<BoardsIconInActive height={16.5} width={19.5} />}
                                placeholder="Select Branch"
                                options={branchesDD || []}
                                value={branchIds}
                                onChange={(e) => this.onChangeBranch(e.value)}
                                optionLabel="name"
                                optionValue="key"
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="col-3">
                            <ClassetMultiSelect
                                label="Board"
                                icon={<BoardsIconInActive height={16.5} width={19.5} />}
                                placeholder="Select Board"
                                options={boards || []}
                                value={boardIds}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                optionLabel="boardName"
                                optionValue="boardId"
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="col-3">
                            <ClassetMultiSelect
                                label="Grade"
                                icon={<GradesIcon />}
                                placeholder="Select (Board - Grade)"
                                options={classes || []}
                                value={classIds}
                                onChange={(e) => this.onChangeClass(e.value)}
                                optionLabel="className"
                                optionValue="classId"
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="col-3">
                            <ClassetDropdown
                                label="Section"
                                icon={<GradesIcon />}
                                placeholder="Select (Board - Grade - Section)"
                                options={sections || []}
                                value={sectionId}
                                onChange={(e) => this.onChangeSection(e.value)}
                                optionLabel="sectionName"
                                optionValue="sectionId"
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
                <Toast ref={this.toast} position="bottom-right" />
                {isLoading && <LoadingComponent />}
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest?.data?.data?.filter((each) => each.level === 1) || [],
    permissionIds: state.currentUserPerms?.permissionIds,
    userInfo: state.currentUserPerms?.userInfo || {}
});

export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(BranchToSectionApprovalHierarchy);
