import React, { Component } from 'react';
import { connect } from 'react-redux';
import Service from '../../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams } from '../../../../utile';
import Authentication from '../../../session';

import { ActiveTag, BoardsIconInActive, CancelRed, CrossInBox, GreenCheck, InactiveTag, PopupGreeTick } from '../../../svgIcons';
import LoadingComponent from '../../../loadingComponent';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import withRouter from '../../../lib/withRouter';
import { RadioButton } from 'primereact/radiobutton';
import ClassetStudentSearch from '../../../../classetComponents/classetStudentSearch';
import { debounce } from 'lodash';
import BranchToSectionApprovalHierarchy from '../../../BaseDropdownComponents/BranchToSectionApprovalHierarchy';
import { getBranchesLatest } from '../../../../store/actions';

const searchTypeOptions = [
    { label: 'Student Name', value: 'studentName' },
    { label: 'Enrollment Number', value: 'enrollmentNumber' }
];

class StudentStatusChangeRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            lazyParams: BasicLazyParams,
            changeRequests: [],
            totalRecords: 0,
            isDialogOpenForApproveStudent: false,
            isRejectTransferDialogOpen: false,
            rowData: {},
            dropdownsData: {},
            flagForTransfer: null,
            searchValue: '',
            searchLevel: 1,
            searchType: searchTypeOptions[0].value,
            dropdownsDilogData: {}
        };
        this.debouncedApiCall = debounce(this.filterUsersData, 500);
        this.service = new Service();
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({ dropdownsData }, this.filterUsersData);
    };

    filterUsersData = () => {
        this.setState({ isLoading: true, changeRequests: [] });

        const { lazyParams, dropdownsData, searchLevel, searchType, searchValue } = this.state;
        // const url = `${baseUrlAdmin}/statusChange/get/all?academicYear=${localStorage.getItem('userAcademicYear')}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}`;
        const url = `${baseUrlAdmin}/statusChange/get/all?academicYear=${localStorage.getItem('userAcademicYear')}`;
        let payload = {
            sectionId: searchLevel === 1 ? dropdownsData?.sectionId : undefined,
            isUserNameSearch: searchLevel === 2 && searchType === 'studentName',
            username: searchValue,
            assignedHeirarchy: dropdownsData?.heirarchy
        };

        if (searchLevel === 1 && !dropdownsData?.sectionId) {
            payload.branchIds = dropdownsData?.branchIds || [];
        }

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res?.status && res.res?.status) {
                    this.setState({
                        isLoading: false,
                        changeRequests: res.res?.data || [],
                        totalRecords: res.res?.totalRecords || 0
                    });
                } else {
                    throw new Error(res.errMessage || 'An error occurred');
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            });
    };

    componentDidMount() {
        if (this.state.dropdownsData?.sectionId) {
            this.filterUsersData();
        }
        this.props.getBranchesLatest();
    }

    onPage = (event) => {
        this.setState(
            (prevState) => ({
                lazyParams: { ...prevState.lazyParams, ...event }
            }),
            this.filterUsersData
        );
    };

    onSort = (event) => {
        this.setState(
            (prevState) => ({
                lazyParams: { ...prevState.lazyParams, ...event }
            }),
            this.filterUsersData
        );
    };

    onFilter = (event) => {
        event['first'] = 0;
        this.setState(
            (prevState) => ({
                lazyParams: { ...prevState.lazyParams, filters: event.filters }
            }),
            this.filterUsersData
        );
    };

    updateTransfer = (approveStatus) => {
        const { rowData, dropdownsData } = this.state;
        if (rowData && Object.keys(rowData).length) {
            const payload = {
                ...rowData,
                approveStatus,
                statusChangeRequest: 'DONE',
                handledBy: rowData?.handledBy || [],
                levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == rowData?.branchId)
            };
            const url = `${baseUrlAdmin}/statusChange/update`;

            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isDialogOpenForApproveStudent: false,
                            isRejectTransferDialogOpen: false
                        });
                        this.toast.show({ severity: 'success', summary: `Successfully ${this.state.flagForTransfer}ED`, detail: res.message, life: 3000 });
                        this.filterUsersData();
                    } else {
                        throw new Error(res.errMessage || 'An error occurred');
                    }
                })
                .catch((error) => {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                });
        } else {
            this.toast.show({ severity: 'error', summary: 'No Record Selected', detail: 'Please select at least one record', life: 3000 });
        }
    };

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value });
    };

    handleSetSearchValue = (value) => {
        this.setState({ searchValue: value }, () => {
            this.debouncedApiCall();
        });
    };

    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value }, this.filterUsersData);
    };

    render() {
        const { changeRequests, isLoading, isDialogOpenForApproveStudent, isRejectTransferDialogOpen, rowData, searchLevel, flagForTransfer } = this.state;

        return (
            <div>
                <div className="ma-main">
                    <div className="datatable-crud-demo ma-m30 mx-2 mb-5">
                        <div className="flex">
                            <div className="flex flex-column justify-content-center align-items-center mt-3">
                                <RadioButton inputId="ingredient1" onChange={() => this.handleSearchLevel(1)} checked={searchLevel === 1} />
                            </div>
                            <div>
                                <BranchToSectionApprovalHierarchy selectedValue={searchLevel} setValues={this.setDropdownValues} approvalType={'studentStatusChange'} />
                            </div>
                        </div>
                        <div style={{ marginLeft: '100px' }} className="flex justify-content-start align-items-center">
                            <span className="">OR</span>
                        </div>
                        <div className="flex mt-4">
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={() => this.handleSearchLevel(2)} checked={searchLevel === 2} />
                            </div>
                            <div className="field ml-4">
                                <ClassetStudentSearch selectedValue={searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                            </div>
                        </div>
                    </div>

                    <div className="card datatable-crud-demo ma-m30 m-2">
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            lazy
                            value={changeRequests}
                            dataKey="id"
                            // paginator
                            className="dataTableValuesText"
                            responsiveLayout="scroll"
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            // onPage={this.onPage}
                            // first={this.state.lazyParams.first}
                            // rows={this.state.lazyParams.rows}
                            // totalRecords={this.state.totalRecords}
                            // rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            // globalFilter={this.state.globalFilter}
                        >
                            <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" field="given_name" header="Student Name" />
                            <Column style={{ width: '17rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" field="branchName" header="Branch" />
                            <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" field="sectionName" header="Section" />
                            <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" field="phone_number" header="Phone Number" />
                            <Column
                                style={{ width: '15rem', height: '6rem' }}
                                alignHeader={'center'}
                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                header="Status"
                                body={(rowData) => (rowData?.status === 'Active' ? <ActiveTag /> : <InactiveTag />)}
                            />
                            <Column
                                style={{ width: '15rem', height: '6rem' }}
                                alignHeader={'center'}
                                sortable
                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                header="Approve / Reject"
                                body={(rowData) => (
                                    <div className="flex justify-content-evenly">
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                this.setState({
                                                    isDialogOpenForApproveStudent: true,
                                                    rowData: rowData,
                                                    branchId: rowData?.tobranchId,
                                                    branchName: rowData?.tobranchName,
                                                    flagForTransfer: 'APROVE'
                                                })
                                            }
                                        >
                                            <GreenCheck />
                                        </div>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                this.setState({
                                                    isRejectTransferDialogOpen: true,
                                                    rowData: rowData,
                                                    branchId: rowData?.tobranchId,
                                                    branchName: rowData?.tobranchName,
                                                    flagForTransfer: 'REJECT'
                                                })
                                            }
                                        >
                                            <CancelRed />
                                        </div>
                                    </div>
                                )}
                            />
                        </DataTable>

                        <Toast ref={(el) => (this.toast = el)} position="bottom-right" />

                        <Dialog
                            visible={isDialogOpenForApproveStudent}
                            style={{ width: 400 }}
                            header={() => (
                                <div className="mt-2 formHeadingInter">
                                    <div className="mb-3">
                                        <PopupGreeTick />
                                    </div>
                                    <p style={{ fontWeight: 'bold', fontSize: 20 }}>Approve Status Change</p>
                                </div>
                            )}
                            footer={() => (
                                <div className="" style={{ display: 'flex' }}>
                                    <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => this.updateTransfer('APROVE')}>
                                        <p className="text-xl ml-2">Accept</p>
                                    </Button>
                                    <Button
                                        style={{ borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 0 }}
                                        onClick={() => this.setState({ isDialogOpenForApproveStudent: false })}
                                    >
                                        <p className="text-xl">Cancel</p>
                                    </Button>
                                </div>
                            )}
                            onHide={() => this.setState({ isDialogOpenForApproveStudent: false })}
                        >
                            <p className="topicName ml-6" style={{ color: 'black', alignSelf: 'center', paddingBottom: 10 }}>
                                <p>Student Name : {rowData?.given_name}</p>
                                <p>Branch Name: {rowData?.branchName}</p>
                                <p>Phone number : {rowData?.phone_number}</p>
                                <p>Request Raised by : {rowData?.requestRaisedByName?.slice(3)}</p>
                                {rowData?.user_info?.status === 'Active' ? (
                                    <p style={{ color: 'green' }} className="mt-3">
                                        *Change student status as InActive
                                    </p>
                                ) : (
                                    <p style={{ color: 'green' }} className="mt-3">
                                        *Change student status as Active
                                    </p>
                                )}
                            </p>
                        </Dialog>

                        <Dialog
                            visible={isRejectTransferDialogOpen}
                            style={{ width: 400 }}
                            header={() => (
                                <div className="mt-2 formHeadingInter">
                                    <div className="mb-3">
                                        <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                    </div>
                                    <p style={{ fontWeight: 'bold', fontSize: 20 }}>Reject Status Change</p>
                                </div>
                            )}
                            footer={() => (
                                <div className="flex justify-content-center ">
                                    <Button className="formSubmitButton px-6" style={{ border: 0 }} onClick={() => this.updateTransfer('REJECT')}>
                                        <p className="formSubmitButtonText">Reject</p>
                                    </Button>
                                    <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejectTransferDialogOpen: false })}>
                                        <p className="formCancelButtonText">Cancel</p>
                                    </Button>
                                </div>
                            )}
                            onHide={() => this.setState({ isRejectTransferDialogOpen: false })}
                        >
                            <p className="topicName ml-6" style={{ color: 'black', alignSelf: 'center', paddingBottom: 10 }}>
                                <p>Student Name : {rowData?.given_name}</p>
                                <p>Branch Name: {rowData?.branchName}</p>
                                <p>Phone number : {rowData?.phone_number}</p>
                                <p>Request Raised by : {rowData?.requestRaisedByName?.slice(3)}</p>
                            </p>
                        </Dialog>
                        {isLoading && <LoadingComponent />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    branchData: (state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each) => each.level === 1)) || []
});

export default connect(mapStatesToProps, {
    getBranchesLatest
})(Authentication(withRouter(StudentStatusChangeRequests)));
