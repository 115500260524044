import React, { Component } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';

// import Navbar from './../navbar';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';

import UserManagement from './UserManagement/index';
import RoleManagement from './RoleManagement';
import CreateRoleManagement from './RoleManagement/createRole';
import CreateUserManagement from './UserManagement/createUserManagement';
import { primaryColor } from '../../store/apiConstants';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from './../services';
import './styles.scss';
import { getPermissionList, getSelectedModules } from '../../store/actions/getPermissionsAction';
import { addRole, updateAddRolesRequest } from '../../store/actions/rolesAction';
import { selectPermissions } from '../../store/selectors/authorize';
import { Toast } from 'primereact/toast';
import { faL } from '@fortawesome/free-solid-svg-icons';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';

const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' }
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' };

const tabs = [{ label: 'Vehicle Management' }, { label: 'Route Management' }, { label: 'Assign Management' }, { label: 'Transport Fee ' }];

class TransportIndex extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.isLoadingss, 'isLoadingisLoadingisLoading');
        this.state = {
            currentTab: 0,
            totalRecords: 0,
            globalSearch: '',
            globalFilter: '',
            columns: [{ columnName: 'Role Name' }, { columnName: 'Role Type' }],
            roles: [],
            editRoleData: null,
            editModules: null,
            showSearch: false,
            isShowCreate: false,
            editUserData: {},
            isAdd: false,
            dialogData: {},
            warningDialog: warningDailogInit,
            lazyParams: BasicLazyParams,
            showHideHierarchyChartModal: false,
            callApi: false,
            isLoading: false
        };
        this.service = new Service();
    }

    onTabChange = (currentTab, data) => {
        if (data?.user_info?.branchs) {
            data.branches = data?.user_info?.branchs;
        }
        if (data?.user_info?.boardId) {
            data.boards = data?.user_info?.boardId;
        }
        this.setState({
            currentTab,
            editUserData: data
        });
    };
    onTabChangeforForm = (currentTab, type) => {
        if (type == 'Edit') {
            this.toast.show({ severity: 'success', summary: 'User has been updated succesfully', detail: '', life: 3000 });
        } else {
            this.toast.show({ severity: 'success', summary: 'User has been created succesfully', detail: '', life: 3000 });
        }

        this.setState({
            currentTab,
            editUserData: {}
        });
    };

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className="ma-tab-title">Transport Management</h4>
            </React.Fragment>
        );
    };

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('calleddiddddidddup', prevState, 'this.state.callApi', this.state.callApi);
        if (prevState.callApi !== this.state.callApi) {
            console.log('calleddiddddidddupinsss', prevState);
            this.getRoles();
        }
    }

    componentDidMount() {
        this.showEditRoleDialog(null, null, false);

        console.log('calledMount');

        this.getRoles();

        this.props.getPermissions();
        this.props.getBranchesLatestData();
        this.props.getBoardsData();

        if (!this.props.permissionsMasterList.length) {
            this.props.getPermissions();
        }
    }

    showEditRoleDialog = (editRoleData, editModules, edit) => {
        this.setState({
            editRoleData,
            editModules,
            isAdd: edit
        });
    };

    getApiCall = (callApiFlg) => {
        this.setState({
            callApi: true
        });
    };

    getRoles = () => {
        this.setState({
            isLoading: true
        });
        let lazyParams = this.state.lazyParams;
        let globalSearch = this.state.globalSearch;
        let url = `${baseUrlAdmin}/authorize/getRoles?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service
            .get(url, true)
            .then((res) => {
                if (res && res.res && res.res.data) {
                    this.setState({
                        roles: res.res.data.map((r, index) => {
                            r.slNo = index + 1;
                            return r;
                        }),
                        //roles: res.res.data,
                        totalRecords: res.res.totalRecords,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
            });
    };

    onPage = (event) => {
        console.log('CALLPAGE');
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getRoles);
    };

    handleEditUserData = (data) => {
        this.setState({ editUserData: data });
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getRoles);
    };

    render() {
        return (
            <>
                <div>
                    <div className="">
                        <div className="grid ml-1 mr-1 h-4rem">
                            <div className="md:col-12 lg:col-12 xl:col-12">
                                <div className="grid doubts-tabs ">
                                    <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                        <p
                                            className="tabheadText"
                                            onClick={() => {
                                                this.onTabChange(0);
                                            }}
                                        >
                                            Role Management{' '}
                                        </p>
                                    </div>
                                    <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                        <p
                                            className="tabheadText"
                                            onClick={() => {
                                                this.onTabChange(1);
                                            }}
                                        >
                                            Create Role
                                        </p>
                                    </div>
                                    <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                        <p
                                            className="tabheadText"
                                            onClick={() => {
                                                this.onTabChange(2);
                                            }}
                                        >
                                            User Management
                                        </p>
                                    </div>
                                    <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 3 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                        <p
                                            className="tabheadText"
                                            onClick={() => {
                                                this.onTabChange(3, {});
                                            }}
                                        >
                                            Create User
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.currentTab == 0 && (
                            <>
                                <Authorizer permId={PERMISSIONS.ROLE_MANAGEMENT_LIST}>
                                    <RoleManagement
                                        onTabChange={this.onTabChange}
                                        rolesData={this.state.roles}
                                        totalRecords={this.state.totalRecords}
                                        lazyParams={this.state.lazyParams}
                                        onPage={this.onPage}
                                        isAdd={this.state.isAdd}
                                        permissionsMasterList={this.props.permissionsMasterList.length > 0 && this.props.permissionsMasterList}
                                        showEditRoleDialog={this.showEditRoleDialog}
                                        getApiCall={this.getApiCall}
                                        isLoading={this.state.isLoading}
                                        onSort={this.onSort}
                                        getRoles={this.getRoles}
                                    />
                                </Authorizer>
                            </>
                        )}
                        {this.state.currentTab == 1 && (
                            <>
                                <Authorizer permId={PERMISSIONS.CREATE_ROLE}>
                                    <CreateRoleManagement
                                        isAdd={this.state.isAdd}
                                        rolesData={this.state.roles}
                                        editRoleData={this.state.editRoleData}
                                        editModules={this.state.editModules}
                                        showEditRoleDialog={this.showEditRoleDialog}
                                        onTabChange={this.onTabChange}
                                        permissionsMasterList={this.props.permissionsMasterList.length > 0 && this.props.permissionsMasterList}
                                        getApiCall={this.getApiCall}
                                        getRoles={this.getRoles}
                                        isLoading={this.state.isLoading}
                                    />
                                </Authorizer>
                            </>
                        )}
                        {this.state.currentTab == 2 && (
                            <>
                                <Authorizer permId={PERMISSIONS.USER_MANAGEMENT_LIST}>
                                    <UserManagement
                                        onTabChange={this.onTabChange}
                                        onEditUserData={() => {
                                            this.handleEditUserData();
                                        }}
                                    />
                                </Authorizer>
                            </>
                        )}

                        {this.state.currentTab == 3 && (
                            <>
                                <Authorizer permId={PERMISSIONS.CREATE_USER}>
                                    <CreateUserManagement onTabChange={this.onTabChangeforForm} editUser={this.state.editUserData} />
                                </Authorizer>
                            </>
                        )}
                    </div>
                </div>

                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    getPermissions: () => dispatch(getPermissionList()),
    getBranchesLatestData: () => dispatch(getBranchesLatest()),
    getBoardsData: () => dispatch(getBoardsData(true))
});

const mapStatesToProps = (state) => ({
    permissionsMasterList: selectPermissions(state)
});
export default connect(mapStatesToProps, mapDispatchToProps)(Authentication(withRouter(TransportIndex)));
