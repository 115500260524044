import AWS from 'aws-sdk';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

const USER_POOL_ID = process.env.REACT_APP_AWS_ADMIN_POOL_ID;
const CLIENT_ID = process.env.REACT_APP_AWS_CLIENT_ID;
const IDENTITY_POOL_ID = process.env.REACT_APP_S3_IDENTITY_POOL_ID;
const AWS_REGION = process.env.REACT_APP_AWS_REGION;

const poolData = { UserPoolId: USER_POOL_ID, ClientId: CLIENT_ID };
export const userPool = new CognitoUserPool(poolData);

// Function to fetch AWS temporary credentials
export const getInfo = async () => {
    
    let idToken = localStorage.getItem('Bearer');
    AWS.config.update({
        region: AWS_REGION,
    });

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID,
        Logins: {
            [`cognito-idp.${AWS_REGION}.amazonaws.com/${USER_POOL_ID}`]: idToken,
        },
    });

    AWS.config.credentials?.expired && await new Promise((resolve, reject) => {
        AWS.config.credentials.refresh((err) => {
            if (err) {
                reject(err);
            } else {
                resolve();
            }
        });
    });

    return AWS.config.credentials
};

const authenticateUser = async (email, password) => {
    
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
  
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
  
    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: (result) => resolve(result), // Resolve with the result on success
        onFailure: (err) => reject(err), // Reject with the error on failure
      });
    });
  };
  

// Login function
export const login = async () => {
    try {
        // const result = await authenticateUser('atanguuser1', 'n|CXxjE4'); // Wait for authentication
        // console.log('Authentication successful:', result);

        // const idToken = result.getIdToken().getJwtToken(); // Extract ID token

        // let idToken = localStorage.getItem('Bearer');
        // const creds =  await getInfo(idToken);
        // return creds;
    } catch (err) {
        console.error('Authentication failed:', err);
    }
};