export const breadcrumb = [
    { path: '/', parent: 'Dashboard', label: 'Dashboard' },
    { path: '/organization', parent: 'Organization', label: 'Organization Master' },
    { path: '/boards', parent: 'Boards', label: 'Boards List' },
    { path: '/branch', parent: 'Branch Master', label: 'Branch Master' },
    { path: '/admission-create', parent: 'Admission', label: 'Admission Create' },
    { path: '/academic-years', parent: 'Admission', label: 'Academic Years' },
    { path: '/admission-types', parent: 'Admission', label: 'Admission Types' },
    { path: '/application-fees', parent: 'Admission', label: 'Application Fees' },
    { path: '/admission-courses', parent: 'Admission', label: 'Admission Courses' },
    { path: '/bus-fee-plans', parent: 'Finance', label: 'Bus Fee plans' },
    { path: '/fee-collect', parent: 'Finance', label: 'Fee Collect' },
    { path: '/fee-day-sheet', parent: 'Finance', label: 'Fee Day Sheet' },
    { path: '/fee-types', parent: 'Finance', label: 'Fee Types' },
    { path: '/fee-plans', parent: 'Finance', label: 'Fee Plans' },
    { path: '/vehicle-management', parent: 'Transport', label: 'Vehicle Management' },
    { path: '/transport-staff-management', parent: 'Transport', label: 'Transport Staff Management' },
    { path: '/route-management', parent: 'Transport', label: 'Route Management' },
    { path: '/transport-attendance', parent: 'Transport', label: 'Transport Attendence' },
    { path: '/transport-attendance-report', parent: 'Transport', label: 'Transport Attendence Report' },
    { path: '/approvals-assigntransport', parent: 'Approvals', label: 'Assign Transports' },
    { path: '/approvals-transportroute', parent: 'Approvals', label: 'Transport Route' },

    { path: '/curriculum', parent: 'CurriculumMaster', label: 'Curriculum Master' },
    { path: '/assign-content', parent: 'Assign Content', label: 'Assign Content' },
    { path: '/assign-content', parent: 'Assign Content', label: 'Assign Content' },
    { path: '/content', parent: 'Contetn master', label: 'Content Master' },
    { path: '/question-bank', parent: 'Examination', label: 'Question Bank' },
    { path: '/assign-question', parent: 'Examination', label: 'Assign Questions' },
    { path: '/examination', parent: 'Examination', label: 'Exams' },
    { path: '/discussion-board', parent: 'Discussion Board', label: 'Discussion Board' },
    { path: '/askadoubt', parent: 'Ask a Doubt', label: 'Ask a Doubt' },
    { path: '/rolesAndUsers', parent: 'RolesAndUsers', label: 'RolesAndUsers' },
    { path: '/transportManagement', parent: 'Transport Management', label: 'Transport Management' },
    { path: '/admin-manage', parent: 'Admin Management', label: 'Admin Management' },
    { path: '/admins-bulkupload', parent: 'Admin Management', label: 'Bulk Upload' },
    { path: '/finance', parent: 'Settings', label: 'Finance' },
    { path: '/UnAssignFee', parent: 'Finance', label: 'UnAssignFee' },
    { path: '/staff', parent: 'Settings', label: 'Staff Management' },

    { path: '/formlayout', parent: 'UI Kit', label: 'Form Layout' },
    { path: '/input', parent: 'UI Kit', label: 'Input' },
    { path: '/floatlabel', parent: 'UI Kit', label: 'Float Label' },
    { path: '/invalidstate', parent: 'UI Kit', label: 'Invalid State' },
    { path: '/button', parent: 'UI Kit', label: 'Button' },
    { path: '/table', parent: 'UI Kit', label: 'Table' },
    { path: '/list', parent: 'UI Kit', label: 'List' },
    { path: '/tree', parent: 'UI Kit', label: 'Tree' },
    { path: '/panel', parent: 'UI Kit', label: 'Panel' },
    { path: '/overlay', parent: 'UI Kit', label: 'Overlay' },
    { path: '/media', parent: 'UI Kit', label: 'Media' },
    { path: '/menu', parent: 'UI Kit', label: 'Menu' },
    { path: '/menu/seat', parent: 'UI Kit', label: 'Menu' },
    { path: '/menu/payment', parent: 'UI Kit', label: 'Menu' },
    { path: '/menu/confirmation', parent: 'UI Kit', label: 'Menu' },
    { path: '/messages', parent: 'UI Kit', label: 'Messages' },
    { path: '/file', parent: 'UI Kit', label: 'File' },
    { path: '/chart', parent: 'UI Kit', label: 'Charts' },
    { path: '/misc', parent: 'UI Kit', label: 'Misc' },
    { path: '/icons', parent: 'Utilities', label: 'Icons' },
    { path: '/blocks', parent: 'PrimeBlocks', label: 'Blocks' },
    { path: '/crud', parent: 'Utilities', label: 'Crud' },
    { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
    { path: '/timeline', parent: 'Pages', label: 'Timeline' },
    { path: '/invoice', parent: 'Pages', label: 'Invoice' },
    { path: '/help', parent: 'Pages', label: 'Help' },
    { path: '/empty', parent: 'Pages', label: 'Empty Page' },
    { path: '/documentation', parent: 'Pages', label: 'Documentation' },

    { path: '/', parent: 'PrimeBlocks', label: 'Dashboard' },
    { path: '/newusers', parent: 'PrimeBlocks', label: 'New users' },
    { path: '/users', parent: 'PrimeBlocks', label: 'Users' },
    { path: '/parents', parent: 'PrimeBlocks', label: 'Parents' },
    { path: '/leaves', parent: 'PrimeBlocks', label: 'Leaves' },
    { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
    { path: '/user-management', parent: 'PrimeBlocks', label: 'User ManageMent' },
    { path: '/user-bulkupload', parent: 'PrimeBlocks', label: 'Bulk Upload' },
    { path: '/ticket-management', parent: 'PrimeBlocks', label: 'Ticket Management' },
    { path: '/admissionManagement', parent: 'Home', label: 'Admission' },
    { path: '/schoolSetup', parent: 'Home', label: 'School Setup' },
    { path: '/notifications', parent: 'PrimeBlocks', label: 'Notification Management' },
    { path: '/feesetup', parent: 'Home', label: 'Finance' },
    { path: '/financeGeneralSettings', parent: 'Home', label: 'Finance General Settings' },

    { path: '/acadamics', parent: 'Home', label: 'Academics' },

    { path: '/studentInfo', parent: 'Students', label: 'Student Information' },
    { path: '/userBulkupload', parent: 'Students', label: 'Student BulkUpload' },

    { path: '/attendance', parent: 'Students', label: 'Attendance' },
    { path: '/studentTransfer', parent: 'Students', label: 'Student Transfer' },
    { path: '/transferPendingStudents', parent: 'Approvals', label: 'Transfer Pending Students' },

    { path: '/TeacherDairyTemplate', parent: 'Academics', label: 'Teacher Dairy Template' },
    { path: '/AcademicCalendar', parent: 'Academics', label: 'AcademicCalendar' },
    { path: '/TeacherDairy', parent: 'Academics', label: 'Teacher Dairy' },
    { path: '/DigitalLibrary', parent: 'Academics', label: 'Digital Library' },
    { path: '/CreateTest', parent: 'Examination', label: 'Create Test' },
    { path: '/QuestionBank', parent: 'Examination', label: 'Question Bank' },
    { path: '/CreateTestType', parent: 'Examination', label: 'Create Test Type' },
    { path: '/CreateTemplate', parent: 'Examination', label: 'Create Template' },
    { path: '/TestsandExams', parent: 'Examination', label: 'Tests and Exams' },

    { path: '/GradingSystem', parent: 'Prograss Reports', label: 'Grading System' },
    { path: '/PerformanceAnalytics', parent: 'Prograss Reports', label: 'Performance Analytics' },
    { path: '/GradeBookTemplate', parent: 'Prograss Reports', label: 'Grade Book Template' },
    { path: '/GradeBook', parent: 'Prograss Reports', label: 'Grade Book' },
    { path: '/ApprovalHierarchy', parent: 'Academics', label: 'Approval Hierarchy' },
    { path: '/Reports', parent: 'Academics', label: 'Reports' },
    { path: '/myTimeTable', parent: 'Timetable', label: 'My Timetable' },
    { path: '/requestSubstitution', parent: 'Timetable', label: 'Request Substitution' },
    { path: '/lagReport', parent: 'Timetable', label: 'Lag Report' },
    { path: '/timeTableReports', parent: 'Timetable', label: 'TimeTable Reports' },
    { path: '/enquiry', parent: 'Admissions', label: 'New Enquiry Form' },
    { path: '/addEnquiry', parent: 'Enquiry', label: 'Add Enquiry' },
    { path: '/application', parent: 'Enquiry', label: 'Application' },
    { path: '/collectFee', parent: 'Finance', label: 'CollectFee' },
    { path: '/assignFee', parent: 'Finance', label: 'AssignFee' },
    { path: '/feeConcession', parent: 'Finance', label: 'Concession' },
    { path: '/feeRefund', parent: 'Finance', label: 'Refund' },

    { path: '/admission-application', parent: 'Admissions', label: 'Application' },
    { path: '/communicate-notification', parent: 'Communicate', label: 'Notification' },
    { path: '/approval-hierarchy-status-change', parent: 'Approval Hierarchy', label: 'Status Change' },
    { path: '/approval-hierarchy-student-transfer', parent: 'Approval Hierarchy', label: 'Student Transfer' },
    { path: '/approval-hierarchy-fee-concession', parent: 'Approval Hierarchy', label: 'Fee Concession' },
    { path: '/approval-hierarchy-assign-transport', parent: 'Approval Hierarchy', label: 'Assign Transport' },
    { path: '/approval-hierarchy-transport-route', parent: 'Approval Hierarchy', label: 'Transport Route' },
    { path: '/approval-hierarchy-fee-unassign', parent: 'Approval Hierarchy', label: 'Fee Unassign' },
    { path: '/approval-hierarchy-fee-refund', parent: 'Approval Hierarchy', label: 'Fee Refund' },
    { path: '/admission-exam', parent: 'Admissions', label: 'Exam' },
    { path: '/addExam', parent: 'Exam', label: 'Add Exam' },
    { path: '/admission-registration', parent: 'registration', label: 'Registration Form' },
    { path: '/addRegistration', parent: 'registration', label: 'Add Registration' },
    { path: '/admission-enrollment', parent: 'enrollment', label: 'Enrollment' },
    { path: '/confirm-enrollment', parent: 'enrollment', label: 'Confirm Enrollment' },
    { path: '/approvals-fee-concession', parent: 'Approvals', label: 'Fee concession' },
    { path: '/approvals-fee-refund', parent: 'Approvals', label: 'Fee Refund' },
    { path: '/approvals-Fee-unassign', parent: 'Approvals', label: 'Fee Unassign' },
    { path: '/approvals-studentTransfer', parent: 'Approvals', label: 'Student Transfer' },
    { path: '/approvals-studentstatuschange', parent: 'Approvals', label: 'Student Status Change' },
    { path: '/studentStatusChange', parent: 'Student', label: 'Student Status Change' },
    { path: '/closedConcessionApprovals', parent: 'Approvals', label: 'Fee Concession Report' },
    { path: '/closedRefundApprovals', parent: 'Approvals', label: 'Fee Refund Report' },
    { path: '/closedUnassignApprovals', parent: 'Approvals', label: 'Fee Unassign Report' },
    { path: '/assigntransport', parent: 'Transport', label: 'Assign Transport' },
    { path: '/transportfeereceivables', parent: 'Transport', label: 'Transport Fee Receivables' },
    { path: '/routemanagement', parent: 'Transport', label: 'Route Management' },
    { path: '/settimetable', parent: 'Settings', label: 'Time Table' },
    { path: '/organisation-setup', parent: 'Organization', label: 'Organization Master' },
    { path: '/create-organization', parent: 'Organization', label: 'Create Organization' }
];