import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import LoadingComponent from '../loadingComponent';
import { AddIcon2, ClassetEditIcon } from '../svgIcons';
import { Button } from 'primereact/button';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; // Corrected import for Column
import { BasicLazyParams } from '../../utile';
import withRouter from '../lib/withRouter';
import Authentication from '../../session';
import './style.scss';
import Service from '../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { InputSwitch } from 'primereact/inputswitch';
import CreateOrg from './createOrganization/index';
class OrganizationSetupIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lazyParams: BasicLazyParams,
            globalSearch: '',
            isCreateOrEdit: false,
            organizations: [],
            totalRecords: 0,
            isLoading: false,
            editOrganization: null
        };
        this.service = new Service();
    }

    componentDidMount() {
        this.getOrganizations();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.isCreateOrEdit !== this.state.isCreateOrEdit) {
            this.getOrganizations();
        }
    }

    getOrganizations = () => {
        this.setState({
            isLoading: true,
            organizations: [],
            totalRecords: 0
        });

        const { lazyParams, globalSearch } = this.state;

        const url = `${baseUrlAdmin}/organization/getall?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder === 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;

        this.service
            .post(url, lazyParams.filters, true)
            .then((res) => {
                if (res && res.status && res.res.success) {
                    this.setState({
                        organizations: res.res.data.organizations,
                        totalRecords: res.res.data.totalRecords,
                        isLoading: false
                    });
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({ isLoading: false });
                console.log(e);
            });
    };

    onEdit = (rowData) => {
        this.setState({ editOrganization: rowData, isCreateOrEdit: true });
    };
    setCreateOrEdit = () => {
        this.setState({ editOrganization: null, isCreateOrEdit: !this.state.isCreateOrEdit });
    };
    render() {
        const { isCreateOrEdit, organizations, isLoading } = this.state;

        return (
            <>
                {isCreateOrEdit ? (
                    <CreateOrg editOrganization={this.state.editOrganization} setCreateOrEdit={this.setCreateOrEdit} />
                ) : (
                    <div className="admin-management">
                        <div className="grid mb-6 mt-2">
                            <div className="col-12 flex justify-content-end align-items-center">
                                <Button className="addButtonn" onClick={this.setCreateOrEdit}>
                                    <AddIcon2 />
                                    <p className="">Add Organization</p>
                                </Button>
                            </div>
                        </div>

                        <div className="ma-m30">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={organizations}
                                dataKey="id"
                                paginator
                                lazy
                                className="dataTableValuesText"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Organizations"
                                responsiveLayout="scroll"
                            >
                                <Column header="S.No." body={(rowData, { rowIndex }) => <p>{rowIndex + 1}</p>} style={{ width: '15rem', textAlign: 'left' }} />
                                <Column field="name" header="Organization Name" style={{ width: '15rem', textAlign: 'left' }} sortable />
                                <Column field="title" header="Organization Title" style={{ width: '15rem', textAlign: 'left' }} sortable />
                                <Column header="End Date" body={(rowData) => moment(rowData.tenureEndDate).format('MMM Do YYYY, h:mm:ss a')} style={{ width: '15rem', textAlign: 'left' }} />
                                <Column field="isActive" header="Status" body={(rowData) => <InputSwitch checked={rowData.isActive} />} style={{ width: '15rem', textAlign: 'left' }} />
                                <Column
                                    header="Edit"
                                    body={(rowData, { rowIndex }) => (
                                        <div className="flex ">
                                            <span data-pr-tooltip="Edit Organization" onClick={() => this.onEdit(rowData)} className={`ma-ml10 awl${rowIndex}`}>
                                                <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                            </span>
                                        </div>
                                    )}
                                    style={{ width: '15rem', textAlign: 'left' }}
                                />
                            </DataTable>

                            <Toast ref={(el) => (this.toast = el)} position="bottom-right" />

                            {isLoading && <LoadingComponent />}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default Authentication(withRouter(OrganizationSetupIndex));
