import React, { Component } from 'react';
import { DeleteIconClasset, MailIcon } from '../../svgIcons';
import { Button } from 'primereact/button';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { cloneDeep } from 'lodash';
import { Toast } from 'primereact/toast';
import uuidv4 from 'uuid/v4';

const createFormFields = require('./timeTableTemplate.json');
const formFields = createFormFields;
const Durations = Array.from({ length: 91 }, (_, index) => index + 30).map((l) => {
    return { label: `${l} minutes`, value: `${l}` };
});

const BreakDurations = Array.from({ length: 20 }, (_, index) => index * 5 + 5).map((l) => {
    return { label: `${l} minutes`, value: `${l}` };
});

const Periods = Array.from({ length: 16 }, (_, index) => index + 1).map((l) => {
    return { label: `${l}`, value: `${l}` };
});

const BreakTypes = [
    { label: 'Interval', value: 'Interval' },
    { label: 'Lunch', value: 'Lunch' },
    { label: 'Snacks', value: 'Snacks' },
    { label: 'Assembly', value: 'Assembly' }
];

export default class TimeTableTemplate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFormFields);

        this.state = {
            breaks: { breakDetails: [{ breakType: '', breakDuration: '', breakTime: '' }] },
            tableData: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            breakDuration: 0
        };
    }

    componentDidMount() {
        console.log(this.props.editData);
        // return;
        if (this.props.editData) {
            console.log(this.props.editData);
            let cnt = 0;
            const updatedBreaks = this.props.editData.periods
                .filter((period) => period.type === 'break')
                .map((breakPeriod, index) => {
                    cnt = cnt + 1;
                    const breakTimeIndex = this.props.editData.periods.findIndex((period) => period === breakPeriod);
                    const updatedBreakPeriod = {
                        ...breakPeriod,
                        breakType: breakPeriod.name,
                        breakDuration: breakPeriod.breakDuration,
                        breakTime: cnt > 1 ? `${breakTimeIndex - 1}` : `${breakTimeIndex}`
                    };

                    return updatedBreakPeriod;
                });
            const editTableData = {
                title: this.props.editData.title,
                periodStartTime: this.props.editData.periodStartTime,
                periodEndTime: this.props.editData.periodEndTime,
                noOfPeriodsPerDay: this.props.editData.noOfPeriodsPerDay,
                duration: this.props.editData.duration
            };
            this.setState({
                tableData: editTableData,
                breaks: { breakDetails: updatedBreaks }
            });
        }
    }

    calculateEndTime = (startTime, totalPeriods, periodLength, breakDuration = 0) => {
        let start = new Date(startTime);
        const totalPeriodTime = totalPeriods * periodLength;
        let totalTime = 0;
        totalTime = totalPeriodTime + parseInt(breakDuration, 10);
        start.setMinutes(start.getMinutes() + totalTime);
        return start;
    };

    calculatePeriodEndTime = (startTime, duration) => {
        let start = new Date(startTime);
        start.setMinutes(start.getMinutes() + duration);
        return start;
    };

    generatePeriods = () => {
        const { periodStartTime, noOfPeriodsPerDay, duration } = this.state.tableData;
        const { breakDetails } = this.state.breaks;
        const map = new Map();

        for (let i = 0; i < breakDetails.length; i++) {
            map.set(breakDetails[i].breakTime, {
                breakType: breakDetails[i].breakType,
                breakDuration: breakDetails[i].breakDuration
            });
        }

        let startTime = new Date(periodStartTime);
        let periods = [];
        let currentStartTime = startTime;

        for (let i = 1; i <= noOfPeriodsPerDay; i++) {
            let periodEndTime = this.calculatePeriodEndTime(currentStartTime, duration);
            periods.push({
                name: `Period ${i}`,
                startTime: currentStartTime.toISOString(),
                endTime: periodEndTime.toISOString(),
                type: 'class',
                periodId: uuidv4()
            });

            currentStartTime = new Date(periodEndTime);
            if (map.has(`${i}`)) {
                const { breakType, breakDuration } = map.get(`${i}`);
                let breakEndTime = this.calculatePeriodEndTime(currentStartTime, breakDuration);
                periods.push({
                    name: `${breakType}`,
                    startTime: currentStartTime.toISOString(),
                    endTime: breakEndTime.toISOString(),
                    type: 'break',
                    breakDuration: breakDuration
                });

                currentStartTime = breakEndTime;
            }
        }

        return periods;
    };
    onPeriodLengthChange = (len, breakDuration = this.state.breakDuration) => {
        if (!this.state.tableData.periodStartTime) return this.toast.show({ severity: 'error', summary: 'Fill the Start Time first', life: 3000 });
        if (!this.state.tableData.noOfPeriodsPerDay && !this.state.tableData.periodStartTime) return this.toast.show({ severity: 'error', summary: 'Fill How many periods first', life: 3000 });
        if (!len) return;
        const startTime = this.state.tableData.periodStartTime;
        const totalPeriods = this.state.tableData.noOfPeriodsPerDay;
        const periodLength = len;
        const endTime = this.calculateEndTime(startTime, totalPeriods, periodLength, breakDuration);

        onTextChange(endTime.toISOString(), 'periodEndTime', this, createFormFields, this.state.tableData, this.state.formValidations, 'tableData', 'formValidations', {}, () => {
            onDropDownChange(len, 'duration', this, createFormFields, this.state.tableData, this.state.formValidations, 'tableData', 'formValidations');
        });
    };
    addBreaks = () => {
        const { breaks } = this.state;
        const len = breaks.breakDetails.length;
        const lastBreak = breaks.breakDetails[len - 1];

        if (!lastBreak || !lastBreak.breakType || !lastBreak.breakDuration || !lastBreak.breakTime) {
            return this.toast.show({ severity: 'error', summary: 'Fill all the fields before adding a new break', life: 3000 });
        }

        const { tableData } = this.state;
        // const endTime = this.calculateEndTime(tableData.periodStartTime, tableData.noOfPeriodsPerDay, tableData.duration, breaks.breakDetails[len - 1].breakDuration);
        // onTextChange(endTime.toISOString(), 'periodEndTime', this, createFormFields, tableData, this.state.formValidations, 'tableData', 'formValidations');

        this.setState((prevState) => ({
            breaks: {
                ...prevState.breaks,
                breakDetails: [...prevState.breaks.breakDetails, { breakType: '', breakDuration: '', breakTime: '' }]
            }
        }));
    };

    generateBreakTimes = (noOfPeriodsPerDay) => {
        return Array.from({ length: noOfPeriodsPerDay - 1 }, (_, index) => index + 1).map((l) => {
            return { label: `${l}`, value: `${l}` };
        });
    };

    removeBreaks = (i) => {
        const { breaks, tableData } = this.state;
        if (breaks.breakDetails.length > 1) {
            let removeDuration = this.state.breaks.breakDetails[i].breakDuration || 0;
            const updatedBreaks = breaks.breakDetails.filter((_, index) => index !== i);
            const totalBreakDuration = updatedBreaks.reduce((acc, breakDetail) => {
                return acc + (parseInt(breakDetail.breakDuration, 10) || 0);
            }, 0);

            this.setState({ breaks: { ...breaks, breakDetails: updatedBreaks } });
            this.onPeriodLengthChange(tableData.duration, totalBreakDuration);
        }
    };

    handleBreakChange = (value, field, index) => {
        const { tableData } = this.state;
        if (field === 'breakDuration') {
            const oldBreakDuration = this.state.breaks.breakDetails[index].breakDuration || 0;
            let totalBreakDuration = 0;
            this.state.breaks.breakDetails.forEach((breakDetail) => {
                if (breakDetail.breakDuration) {
                    totalBreakDuration += parseInt(breakDetail.breakDuration, 10);
                }
            });
            console.log(oldBreakDuration);
            totalBreakDuration = totalBreakDuration - parseInt(oldBreakDuration, 10) + parseInt(value, 10);

            console.log('Total Break Duration:', totalBreakDuration);
            this.setState({ breakDuration: totalBreakDuration });
            this.onPeriodLengthChange(tableData.duration, totalBreakDuration);
        }
        const updatedBreaks = this.state.breaks.breakDetails.map((breakDetail, idx) => {
            if (idx === index) {
                return { ...breakDetail, [field]: value };
            }
            return breakDetail;
        });
        this.setState((prevState) => ({
            breaks: { ...prevState.breaks, breakDetails: updatedBreaks }
        }));
        // if (field === 'breakDuration') {
        //     const { breakDuration, breakTime } = updatedBreaks[index];
        //     if (breakDuration && breakTime) {
        //         const breakEndTime = this.calculateEndTime(tableData.periodStartTime, tableData.noOfPeriodsPerDay, tableData.duration, breakDuration);
        //         onTextChange(breakEndTime.toISOString(), 'periodEndTime', this, createFormFields, tableData, this.state.formValidations, 'tableData', 'formValidations');
        //     }
        // }
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableData.noOfPeriodsPerDay !== this.state.tableData.noOfPeriodsPerDay || prevState.tableData.periodStartTime !== this.state.tableData.periodStartTime) {
            this.onPeriodLengthChange(this.state.tableData.duration, this.state.breakDuration);
        }
    }
    addTimeTableTemplate = () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        const { breaks } = this.state;
        const len = breaks.breakDetails.length;
        const lastBreak = breaks.breakDetails[len - 1];

        if (!lastBreak || !lastBreak.breakType || !lastBreak.breakDuration || !lastBreak.breakTime) {
            return this.toast.show({ severity: 'error', summary: 'Fill all the fields before adding a new break', life: 3000 });
        }

        const data = this.generatePeriods();
        const timeTableTemplateData = {
            title: this.state.tableData.title,
            periods: data,
            academicYear,
            periodStartTime: this.state.tableData.periodStartTime,
            periodEndTime: this.state.tableData.periodEndTime,
            noOfPeriodsPerDay: this.state.tableData.noOfPeriodsPerDay,
            duration: this.state.tableData.duration,
            ...(this.props.editData ? { _id: this.props.editData._id } : {})
        };
        this.props.addNewTemplate(timeTableTemplateData);
    };

    render() {
        const { tableData, formValidations } = this.state;

        return (
            <div className="poppins24">
                <div className="grid mt-5 px-7">
                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetInputText
                            icon={<MailIcon width={24} height={24} color={'#000000'} />}
                            className="border-round-md border-none"
                            label="Name of the Template"
                            required={true}
                            value={tableData.title}
                            onChange={(e) => {
                                onTextChange(e.target.value, 'title', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            placeholder="Template Name"
                        />
                        {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetCalendar
                            required={true}
                            value={tableData.periodStartTime ? new Date(tableData.periodStartTime) : null}
                            onChange={(e) => {
                                onTextChange(e.target.value, 'periodStartTime', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            calendarMode="single"
                            label="Start Time"
                            placeholder="Start Time"
                            timeOnly
                        />
                        {formValidations && !formValidations.fields['periodStartTime'].isValid && <p className="p-error">{formValidations.fields['periodStartTime'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="label"
                            optionValue="value"
                            label={'Total Periods'}
                            required={true}
                            options={Periods}
                            value={tableData.noOfPeriodsPerDay}
                            onChange={(e) => {
                                onDropDownChange(e.target.value, 'noOfPeriodsPerDay', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            placeholder={
                                <div className="flex justify-content-start align-items-center">
                                    <span>Total Periods</span>
                                </div>
                            }
                        />
                        {formValidations && !formValidations.fields['noOfPeriodsPerDay'].isValid && <p className="p-error">{formValidations.fields['noOfPeriodsPerDay'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="label"
                            optionValue="value"
                            label={'Periods Length'}
                            required={true}
                            options={Durations}
                            value={tableData.duration}
                            onChange={(e) => {
                                this.onPeriodLengthChange(e.target.value);
                            }}
                            placeholder={
                                <div className="flex justify-content-start align-items-center">
                                    <span>Periods Length</span>
                                </div>
                            }
                        />
                        {formValidations && !formValidations.fields['duration'].isValid && <p className="p-error">{formValidations.fields['duration'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetCalendar value={tableData.periodEndTime ? new Date(tableData.periodEndTime) : null} calendarMode="single" label="End Time" placeholder="End Time" timeOnly disabled={true} />
                        {formValidations && !formValidations.fields['periodEndTime'].isValid && <p className="p-error">{formValidations.fields['periodEndTime'].errorMsg}</p>}
                    </div>

                    <div className="col-12">
                        <p className="text-3xl" style={{ textDecoration: 'underline', fontWeight: 'bolder', color: 'black' }}>
                            Add Breaks
                        </p>
                        {this.state.breaks.breakDetails.map((obj, index) => (
                            <div className="grid py-0 -mt-2" key={index}>
                                <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        optionLabel="label"
                                        optionValue="value"
                                        label={'Type of Break'}
                                        required={true}
                                        options={BreakTypes}
                                        value={obj.breakType}
                                        onChange={(e) => this.handleBreakChange(e.target.value, 'breakType', index)}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center">
                                                <span>Type of Break</span>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        options={BreakDurations}
                                        optionLabel="label"
                                        optionValue="value"
                                        label={'Break Length'}
                                        required={true}
                                        value={obj.breakDuration}
                                        onChange={(e) => this.handleBreakChange(e.target.value, 'breakDuration', index)}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center">
                                                <span>Break Length</span>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className="grid xl:col-4 lg:col-4 md:col-4 col-12">
                                    <div className="col-10">
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            value={obj.breakTime}
                                            options={this.generateBreakTimes(this.state.tableData.noOfPeriodsPerDay)}
                                            label="Break Time"
                                            optionLabel="label"
                                            optionValue="value"
                                            required={true}
                                            onChange={(e) => this.handleBreakChange(e.target.value, 'breakTime', index)}
                                            placeholder={
                                                <div className="flex justify-content-start align-items-center">
                                                    <span>Break Time</span>
                                                </div>
                                            }
                                        />
                                    </div>

                                    <div className="col-2 pl-4 pt-5 flex justify-content-start align-items-center cursor-pointer" onClick={() => this.removeBreaks(index)}>
                                        <DeleteIconClasset width={22} height={22} />
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="mt-2 flex justify-content-between align-content-center">
                            <Button className="mt-2" style={{ borderRadius: 10, backgroundColor: '#F4F5F6', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', color: 'black' }} onClick={this.addBreaks}>
                                <p className="text-xl">Add Break</p>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="flex justify-content-center align-items-center mt-8">
                    <Button label={this.props.editData ? 'Edit Time Table Template' : 'Add Time Table Template'} className="confirmDialogAcceptBtn px-6 mr-5 w-21rem" onClick={this.addTimeTableTemplate} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}
